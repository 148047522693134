import React from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import { prepareFormValuesObject, getRequiredFieldNames, checkError, getOktaUrls, checkIsInternalUser, getSchemaLabels } from '../../utils/helper';
import DynamicForm from '../shared/DynamicForm';
import { STATUS_FAILED, INTERNAL_USER_RESTRICTION_MESSAGE,USER_ALREADY_EXISTING_MESSAGE, STATUS_ACTIVATED, KEY_ENTER, KEY_TAB, STATUS_CREATED } from '../../utils/constants';
import notify from '../../utils/notify';
import PropTypes from "prop-types";
import { ENABLE_ERROR_NOTIFIER } from '../../utils/constants';
import { SSO_ADMIN_BASE_URL, SSO_ADMIN_PUB_URL } from '../../api/constants';

class RegistrationPage extends React.Component {
  state = {
    formData: [],
    error: {},
    loginfrom: null
  }

  componentDidMount() {
    const { getProfileSchema, application } = this.props;
    const appSpecificSchema = application.profile_schema;
    //const url = application.external_okta_sandbox_url;
    const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
    const includeCredentialSchema = true;
    const editMode = true;
    const isRegister = true;
    getProfileSchema(url, {appSpecificSchema, includeCredentialSchema, editMode, isRegister});  
    const {  match } = this.props;
    const { loginfrom } = match.params;
    this.setState({loginfrom});
  }

  componentDidUpdate = (prevProps) => {
    const { schema, status, history, error, application } = this.props;
    const {loginfrom} = this.state;

    if(prevProps.schema !== schema) {
      this.setState({
        schema
      });
    }

    if(prevProps.status !== status) {
      
      // Created Profile
      if(status === STATUS_CREATED) {
        let createMsg = 'User registered successfully. Please check your inbox to activate.';

        if (application.app_id.includes('lmsc'))
          createMsg = 'User registered successfully.';

        notify({message: createMsg}, 'success');
     
        if(loginfrom=='ssologin'){
          history.push(`/${application.app_id}/ssologin`)
        } else {
          history.push(`/${application.app_id}/login`)
        }
        
      }
      if(status === STATUS_FAILED) {
        if(error.description.includes("field already exists")){
          error['email']= USER_ALREADY_EXISTING_MESSAGE;
      
         this.setState({error})
        }
        else
        notify({message: error.description}, 'error');
      }
    }
  }

  render() {
    const { classes, mfaQuestions, recoveryQuestions, application } = this.props;
    const { schema, error, registrationData } = this.state;
    let mfaQuestions_utd; let recoveryQuestions_utd;
    const summaryButtons = this.prepareSummaryButtons();
    const values = prepareFormValuesObject(schema, registrationData);

    let options = {
      'recoveryquestion': recoveryQuestions,
      'mfaquestiontype': mfaQuestions
    };
    //Issue fixed #142596 - User can set same question for security question and MFA question
    if(registrationData){
      if(registrationData.recoveryquestion){
        mfaQuestions_utd = mfaQuestions.filter(item => item.label !== registrationData.recoveryquestion);
        options.mfaquestiontype = mfaQuestions_utd;
      }

      if(registrationData.mfaquestiontype){
        recoveryQuestions_utd = recoveryQuestions.filter(item => item.id !== registrationData.mfaquestiontype);
        options.recoveryquestion = recoveryQuestions_utd;
      }
    }

    return (
      <div className="app-container">
        <Grid container spacing={0}>
          <Grid item xs={1} className=""></Grid>
          <Grid item xs={10} className="">
            <div className="app-content">
              <br />
              <h2 className="app-content-h2" >Tools for Your Application Needs</h2>
              <h3 className="app-content-h3" >
                Sign in or register once to access all of your Emerson accounts.
              </h3>

              <h5 className="app-content-h5" >
                Get access to Emerson’s Tools using just one username and one password with our new
                seamless platform. While we build, please note that you may still be required to use
                existing usernames and passwords for some web applications.
              </h5>
            </div>
          </Grid>
          <Grid item xs={1} className=""></Grid>
        </Grid>
        <br />
        <Grid container className="content-area" >
          <Grid item xs={1} md={2}></Grid> 
          <Grid item container xs={10} md={8} 
            className="shadow-bordered-container"
            style={{background: '#FFF', padding: '20px'}}>
            <Grid container>
              <Grid container spacing={0} className="">
                <Grid item xs={12}>
                  <Typography component="h5" variant="h5" className="app-content-h2 app-content-center">
                    Register Now
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography className="app-content-h4 app-content-center" component="h4" variant="h4">
                    In order to access your Emerson accounts, you will need to register your information and create a password using the fields below.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <span className="app-content-center" style={{
                        display: 'flex',
                        justifyContent: 'center',
                  }}>
                    <span className="app-content-h5">Have an account?</span> 
                    <Link className="app-button-href" to={`/${application.app_id}/login`}
                      style={{fontSize: 'inherit'}}>Sign In</Link>
                  </span>
                </Grid>
              </Grid>
            </Grid>
            { 
              Object.keys(values).length > 0 && (
                <DynamicForm 
                  schema={schema} 
                  classes={classes}
                  summaryButtons={summaryButtons}
                  options={options}
                  allowEdit={true}
                  values={values} 
                  error={error} 
                  handleChange={this.handleChange}
                  handleKeyPress={this.handleKeyPress}
                />
              )
            }
          </Grid>
          <Grid item xs={1} md={2}></Grid> 
        </Grid>
      </div>
    );
  }

  handleRoute = () => {
    const { history, application } = this.props;
    history.push(`/${application.app_id}/login`);
  }
  
  handleChange = (event, registrationData) => {
    const field = event.target.name
    const error = {...this.state.error};
    let newErrors = {};

    if(error.hasOwnProperty(field)) {
      const { schema } = this.props;
      delete error[field];
      let reqFieldNames = getRequiredFieldNames(schema).filter(f => f === field);
      let schemaLabels = getSchemaLabels(schema);
      newErrors = checkError(reqFieldNames, registrationData, schemaLabels);
      
    }

    this.setState({ registrationData, error: {...error, ...newErrors} });
  };

  handleKeyPress = (event, data) => {
    if (event.key === KEY_ENTER) {
      this.registerUser(event, data);
    } else if(event.key === KEY_TAB) {
      const { schema } = this.props;
      const error = {...this.state.error};
      const field = event.target.name;
      if(field === 'email' && data.email !== '' && data.email !== null ){
        let isInternalUser = checkIsInternalUser(data.email);
        if(isInternalUser) {
          let error = {...this.state.error};
         // notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
         error['email']= INTERNAL_USER_RESTRICTION_MESSAGE;
      
         this.setState({error})
          return;
        }
      }
      let reqFieldNames = getRequiredFieldNames(schema).filter(f => f === field);
      let schemaLabels = getSchemaLabels(schema);
      let newErrors = checkError(reqFieldNames, data, schemaLabels);
      this.setState({error: {...error, ...newErrors}});
    }
  }

  registerUser = (e, registerPayload) => {
    e.preventDefault();
    
    let data = {...registerPayload};

    const { createProfile, schema, application } = this.props;
    let reqFieldNames = getRequiredFieldNames(schema);
    let schemaLabels = getSchemaLabels(schema);
    let err = checkError(reqFieldNames, data, schemaLabels) ;
    const {loginfrom} = this.state;
    this.setState({error:err}, () => {
      const { error } = this.state;
      if(Object.keys(error).length === 0) {
        let isInternalUser = checkIsInternalUser(data.email);
        if(isInternalUser) {
         // notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
          error['email']= INTERNAL_USER_RESTRICTION_MESSAGE;
          
          this.setState({error})
          return;
        }else{
          let oktaUrls = getOktaUrls(data.email, application);
          //let url = oktaUrls.sandboxUrl;
          const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
          data.login = data.email;
          data.hostname = window.location.origin;
          data.activateRedirect = loginfrom;
          createProfile(url, data);
        }
        
      }
      else {
        if(ENABLE_ERROR_NOTIFIER)
          notify({message: 'Required field values are missing!'}, 'error');
      }
    });
  }

  cancelRegister = () => {
    const { history, application } = this.props;
    history.push(`/${application.app_id}/login`);
  }

  prepareSummaryButtons = () => {
    let summaryButtons = [
      {
        id: 1,
        label: 'CREATE ACCOUNT',
        onClick: this.registerUser,
        class: 'app-button-primary'
      },
      {
        id: 2,
        label: 'CANCEL',
        onClick: this.cancelRegister,
        class: 'app-button-cancel'
      }
    ];
    return summaryButtons;
  }
}
RegistrationPage.propTypes = {
  getProfileSchema: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  status: PropTypes.string, 
  history: PropTypes.object, 
  error: PropTypes.object,
  classes: PropTypes.object, 
  mfaQuestions: PropTypes.arrayOf(PropTypes.object), 
  recoveryQuestions: PropTypes.arrayOf(PropTypes.object),
};
export default withStyles()(RegistrationPage);