import React from 'react';
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import PasswordStrengthIndicator from '../../shared/PasswordStrengthIndicator';
import validator from 'validator';
import notify from '../../../utils/notify';
import { STATUS_FAILED, STATUS_SUCCESS, ENABLE_ERROR_NOTIFIER, KEY_ENTER, KEY_TAB } from '../../../utils/constants';
import { getOktaUrls } from '../../../utils/helper';
import ErrorMesasge from '../../shared/ErrorMessage';
import PasswordInput from '../../shared/PasswordInput';
import { SSO_ADMIN_PUB_URL } from '../../../api/constants';

class ResetPassword extends React.Component {
  state = { 
    error:[],
    hasProfileError:false,
    message:'',
    credentials: {
      currentSec: "",
      sec: "",
      confirmSec: ""
    }
  };

  componentDidUpdate = prevProps => {
    const { status, error, history, match, profile } = this.props;
    const { appId } = match.params;

    if(prevProps.status !== status) {
      let message ='Your password has been changed successfully.';
      if(status === STATUS_SUCCESS) {
        if(ENABLE_ERROR_NOTIFIER){
          this.setState({hasProfileError:false, message:''}) ;
          
        } else {

          this.setState({hasProfileError:true,message}) 
        }
        notify({message: message}, 'success');
        history.push({
          pathname: `/${appId}/my-profile`,
          state: {
              login: profile.login
          }
        })
      }
      if(status === STATUS_FAILED) {
        if(ENABLE_ERROR_NOTIFIER){
          this.setState({hasProfileError:false,message:''}) ;
          notify({message: error.description}, 'error');
        }else {
            this.setState({hasProfileError:true,message:error}) 
        }
      }
    }
  }

  render() {
    const { hasProfileError, message } = this.state;
    const inputClass = "app-content-text-width";
    const errorInputClass = "app-content-text-width-with-error";
    let error = Object.assign({}, this.state.error);
    let credentials = Object.assign({}, this.state.credentials);
    return (
      <div className="app-container-fp">
        <Container maxWidth="sm" spacing={5} className="app-content-padding">
          <Card className="shadow-bordered-container">
            <CardContent>
              <br />
              <Grid container spacing={3} className="">
                <Grid item xs={1} md={1} ></Grid>
                <Grid item xs={10} sm={10}>
                  <Typography className="app-content-h2 app-content-left" component="h5" variant="h5">Change Password</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={10} md={10}>
                      { hasProfileError && <ErrorMesasge message={message}/> }
                  </Grid>
              </Grid>
              <Grid item xs={1} md={1} ></Grid>
              <form className="">
              <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={10} md={10}>
                    <PasswordInput wrapperClass=""
                      wrapperId="currentSec"
                      labelClass="app-content-label"
                      label="Current Password"
                      error={error.currentSec}
                      errorClass="emr-field-error-message"
                      inputClass={error.currentSec ? errorInputClass : inputClass}
                      type="password"
                      name="currentSec"
                      value={credentials.currentSec}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                      autofocus={true}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                </Grid>
              <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={10} md={10}>
                    <PasswordInput wrapperClass=""
                      wrapperId="createPassword"
                      labelClass="app-content-label"
                      label="Create New Password"
                      error={error.sec}
                      errorClass="emr-field-error-message"
                      inputClass={error.sec ? errorInputClass : inputClass}
                      type="password"
                      name="sec"
                      value={credentials.sec}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                </Grid>
                <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={10} md={10}>
                    <PasswordInput wrapperClass=""
                      wrapperId="confirmSec"
                      labelClass="app-content-label"
                      label="Confirm New Password "
                      error={error.confirmSec}
                      errorClass="emr-field-error-message"
                      inputClass={error.confirmSec ? errorInputClass : inputClass}
                      type="password"
                      name="confirmSec"
                      value={credentials.confirmSec}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}></Grid>
                </Grid>
                <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={10} md={10}>
                    <PasswordStrengthIndicator
                      classes={{
                        label: "app-content-label-red app-content-right",
                      }}
                      password={credentials.sec}
                      description={`Password must be a minimum of eight characters and contain at least 
                        one number, one lowercase and one uppercase letter. Must not contain your email or 
                        parts of your email separated by the following characters: , . _ # @.`}
                    />
                  </Grid>
                  <Grid item xs={1} md={1} ></Grid>
                </Grid>

                <Grid container spacing={3} className="">
                  <Grid item xs={1} md={1} ></Grid>
                  <Grid item xs={5} md={5}>
                    <MuiThemeProvider>
                      <ButtonBase
                        className="app-button-primary"
                        variant="contained"
                        color="primary"
                        onClick={() => this.submitForm()}
                      >
                        Submit
                      </ButtonBase>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <MuiThemeProvider>
                      <ButtonBase
                        className="app-button-cancel"
                        variant="contained"
                        color="primary"
                        onClick={(event) => this.handleRoute(event)}
                      >
                        CANCEL
                        </ButtonBase>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
                <Grid item xs={1} md={1} ></Grid>
              </form>
              <br />
            </CardContent>
          </Card>
        </Container>
      </div>
    );
  }

  handleChange = event => {
    let error = Object.assign({}, this.state.error);
    const name = event.target.name;
    const value = event.target.value;
    let credentials = Object.assign({}, this.state.credentials);
    credentials[name] = value;
    this.setState({ credentials }, () => {
      if(error[name]) {
        let newError = this.getFormErrors(name, credentials);
        this.setState({error: newError})
      }
    });
  };

getFormErrors = (name, credentials) => {
  let error = Object.assign({}, this.state.error);
  let fields = Object.keys(credentials).filter(f => name === null || f === name);
  let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g; 

  fields.map(name => {
      let hasValid = false;
      let errorMsg = '';
      if(name === "currentSec") {
        hasValid = !validator.isEmpty(credentials[name] || '');
        errorMsg = "Current Password field cannot be empty";
      } else if(name === "sec") {
        hasValid = !validator.isEmpty(credentials[name] || '');
        errorMsg = "Password field cannot be empty";
        if(hasValid) {
          let metPattern= passwordPattern.test(credentials[name]);
          if(!metPattern) {
            hasValid = false;
            errorMsg = "Please Check the password pattern";
          }
        }
      } else if(name === "confirmSec") {
        hasValid = !validator.isEmpty(credentials[name] || '');
        errorMsg = "Confirm Password field cannot be empty";
        if(hasValid && (credentials['sec'] !== credentials['confirmSec'])) {
          hasValid = false;
          errorMsg = "The passwords entered do not match.";
        }
      }

    if(hasValid) {
      delete error[name];
    } else {
      error[name] = errorMsg;
    }
    return name;
  });
  return error;
}
handleKeyPress = event => {
  if (event.key === KEY_ENTER) {
    this.submitForm();
  } else if(event.key === KEY_TAB) {
    const name = event.target.name;
    let error = this.getFormErrors(name, this.state.credentials);
    this.setState({error})
  }
}

submitForm = () => {

  const { location, changePassword, application, idToken } = this.props;
  let credentials = Object.assign({}, this.state.credentials);
  let error = this.getFormErrors(null, credentials);
  
  this.setState({ error }, () => {
    if(Object.keys(error).length === 0) {
        let data = {
          Login : location.state.id,
          Password: credentials['currentSec'],
          NewPassword : credentials['sec'],
          idToken
        }
        let oktaUrls = getOktaUrls(location.state.id, application);
        //let url = oktaUrls.sandboxUrl;
        const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
        
        changePassword(url, data);
    }
  });
}

handleRoute() {
  //this.props.history.goBack();
  const { history, application, location } = this.props;

  history.push({
    pathname: `/${application.app_id}/my-profile`,
    state: {
        login: location.state.id
    }
  })
}

}
ResetPassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  status : PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ResetPassword;