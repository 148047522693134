export const STATUS_PROGRESS = 'STATUS_PROGRESS';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_FAILED = 'STATUS_FAILED';
export const STATUS_CREATED = 'STATUS_CREATED';
export const STATUS_SAVED = 'STATUS_SAVED';
export const STATUS_VERIFIED = 'STATUS_VERIFIED';
export const STATUS_ACTIVATED = 'STATUS_ACTIVATED';
export const STATUS_CLEARED = 'STATUS_CLEARED';
export const ENABLE_ERROR_NOTIFIER = true;
export const KEY_ENTER = 'Enter';
export const KEY_TAB = 'Tab';

export const INTERNAL_USER_RESTRICTION_MESSAGE = 'You are unauthorized to do this operation. Please contact ITSS Team.';
export const USER_ALREADY_EXISTING_MESSAGE ='The email address you entered already exists. Please return to the Sign In page to log in using this email or use the Forgot Password link to reset your password.';
export const INTERNAL_EMAIL_DOMAIN = 'emerson.com';
export const INTERNAL_DEV_EMAIL_DOMAIN = 'etstest.com';

export const adminMaintenanceTableColumns = [
    {
        name: "app_name",
        label: "Application Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "client_id",
        label: "Client Id",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "status",
        label: "Staus",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "action",
        label: "Action",
        options: {
            filter: false,
            sort: false,
        }
    },
];

export const credentialSchema = {
    "properties":{
        "password": {
            "title": "Password",
            "type": "password",
            "mutability": "READ_WRITE",
            "scope": "NONE",
            "value":"••••••"
        },
        "recoveryquestion": {
            "title": "SECURITY QUESTION",
            "type": "array",
            "mutability": "READ_WRITE",
            "scope": "NONE"        
        },
        "phone_number":{
            "title": "Phone Number",
            "type": "string",
            "mutability": "READ_WRITE",
            "scope": "NONE"
        }
    }
};

export const profileAccountControls = [
    {
        name: 'password',
        label: 'Password',
        placeholder: '........',
        type: 'password',
        required: false,
        mutability: 'READ',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width dummy-password'
    },
    {
        name: 'recoveryquestion',
        label: 'Security Question',
        placeholder: '',
        type: 'array',
        required: false,
        mutability: 'READ',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'recoveryanswer',
        label: 'Security Answer',
        placeholder: '........',
        type: 'string',
        required: false,
        mutability: 'READ',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width dummy-password'
    },
]
export const accountPasswordControls = [
    {
        name: 'password',
        label: 'Create Password',
        placeholder: 'Create Password',
        type: 'password',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'confirm_password',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        type: 'password',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'password-strength',
        label: 'Password Strength',
        placeholder: 'Password Strength',
        type: 'password-strength',
        required: false,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    }
];

export const securityQuestionControls = [
    {
        name: 'recoveryquestion',
        label: 'Security Question',
        placeholder: '',
        type: 'array',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'recoveryanswer',
        label: 'Security Answer',
        placeholder: 'Security Answer',
        type: 'password',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'mfaquestiontype',
        label: 'MFA Question',
        placeholder: '',
        type: 'array',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'mfaanswer',
        label: 'MFA Answer',
        placeholder: 'MFA Answer',
        type: 'password',
        required: true,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    },
    {
        name: 'securuty-strength',
        label: 'securuty-strength',
        placeholder: 'securuty-strength',
        type: 'securuty-strength',
        required: false,
        mutability: 'READ_WRITE',
        wrapperClass: '',
        labelClass: 'app-content-label',
        inputClass: 'app-content-text-width'
    }
   
];

export const SECURITY_QUESTIONS = [
    {
        "id": "disliked_food",
        "value": "What is the food you least liked as a child?",
        "label": "What is the food you least liked as a child?"
    },
    {
        "id": "name_of_first_plush_toy",
        "value": "What is the name of your first stuffed animal?",
        "label": "What is the name of your first stuffed animal?"
    },
    {
        "id": "first_award",
        "value": "What did you earn your first medal or award for?",
        "label": "What did you earn your first medal or award for?"
    },
    {
        "id": "favorite_security_question",
        "value": "What is your favorite security question?",
        "label": "What is your favorite security question?"
    },
    {
        "id": "favorite_toy",
        "value": "What is the toy/stuffed animal you liked the most as a kid?",
        "label": "What is the toy/stuffed animal you liked the most as a kid?"
    },
    {
        "id": "first_computer_game",
        "value": "What was the first computer game you played?",
        "label": "What was the first computer game you played?"
    },
    {
        "id": "favorite_movie_quote",
        "value": "What is your favorite movie quote?",
        "label": "What is your favorite movie quote?"
    },
    {
        "id": "first_sports_team_mascot",
        "value": "What was the mascot of the first sports team you played on?",
        "label": "What was the mascot of the first sports team you played on?"
    },
    {
        "id": "first_music_purchase",
        "value": "What music album or song did you first purchase?",
        "label": "What music album or song did you first purchase?"
    },
    {
        "id": "favorite_art_piece",
        "value": "What is your favorite piece of art?",
        "label": "What is your favorite piece of art?"
    },
    {
        "id": "grandmother_favorite_desert",
        "value": "What was your grandmother's favorite dessert?",
        "label": "What was your grandmother's favorite dessert?"
    },
    {
        "id": "first_thing_cooked",
        "value": "What was the first thing you learned to cook?",
        "label": "What was the first thing you learned to cook?"
    },
    {
        "id": "childhood_dream_job",
        "value": "What was your dream job as a child?",
        "label": "What was your dream job as a child?"
    },
    {
        "id": "place_where_significant_other_was_met",
        "value": "Where did you meet your spouse/significant other?",
        "label": "Where did you meet your spouse/significant other?"
    },
    {
        "id": "favorite_vacation_location",
        "value": "Where did you go for your favorite vacation?",
        "label": "Where did you go for your favorite vacation?"
    },
    {
        "id": "new_years_two_thousand",
        "value": "Where were you on New Year's Eve in the year 2000?",
        "label": "Where were you on New Year's Eve in the year 2000?"
    },
    {
        "id": "favorite_speaker_actor",
        "value": "Who is your favorite speaker/orator?",
        "label": "Who is your favorite speaker/orator?"
    },
    {
        "id": "favorite_book_movie_character",
        "value": "Who is your favorite book/movie character?",
        "label": "Who is your favorite book/movie character?"
    },
    {
        "id": "favorite_sports_player",
        "value": "Who is your favorite sports player?",
        "label": "Who is your favorite sports player?"
    }
];