import { connect } from 'react-redux';
import Header from './Header';

const mapStateToProps = (state) => {
    return {
        application: state.admin.application,
        profile: state.profile.data,
    };
}

export default connect(mapStateToProps, null)(Header);
