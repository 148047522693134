import React from 'react';
import PropTypes from 'prop-types';

const NumberInput = ({ type, max,
    label, name, value, placeholder, error, onChange, onBlur,
    wrapperClass, wrapperId, inputClass, labelClass, errorClass, 
    isRequired, onKeyPress, disabled, autofocus }) => {
   
    const handleKeypress = (e,value,max) => {  
        let data = value + e.key
        if ((data.length <= max)) {           
            let e = {};                
            onKeyPress(e);
        } else {
            e.preventDefault();
        }        
    }
    errorClass=errorClass?errorClass:"alert alert-danger";
    return (
        <div className={wrapperClass} id={wrapperId} >
            {
                label && <label className={labelClass} htmlFor={name}>{label}</label>
            }
            <input 
                type={type} 
                className={inputClass} 
                name={name} 
                value={value} 
                id={name}
                maxLength={max}
                max={9999999999}
                placeholder={placeholder} 
                onChange={onChange} 
                onBlur={onBlur} 
                onKeyPress={(e) => handleKeypress(e,value,max)}
                onKeyDown={onKeyPress}
                aria-required="true" 
                autoComplete="off" 
                required={isRequired} 
                disabled={disabled} 
                autoFocus={autofocus} />
            {
                error && (
                    <div className={errorClass}>{error}</div>
                )
            }
        </div>
    )
}

NumberInput.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string, 
    name: PropTypes.string.isRequired, 
    value: PropTypes.any, 
    placeholder: PropTypes.string, 
    error: PropTypes.string, 
    onChange: PropTypes.func, 
    onBlur: PropTypes.func,
    wrapperClass: PropTypes.string, 
    wrapperId: PropTypes.string, 
    inputClass: PropTypes.string, 
    labelClass: PropTypes.string, 
    errorClass: PropTypes.string,
    isRequired: PropTypes.bool, 
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool, 
    autofocus: PropTypes.bool
}

export default NumberInput;