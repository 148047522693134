import { credentialSchema, securityQuestionControls, accountPasswordControls, profileAccountControls, INTERNAL_EMAIL_DOMAIN, INTERNAL_DEV_EMAIL_DOMAIN, INTERNAL_USER_RESTRICTION_MESSAGE } from './constants';
import validator from 'validator';

export const capitalizeFirstLetterforEachWord = str => {
    return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

export const prepareErrorResponse = (error) => {
    let errorResponse = {
        code: '',
        title: '',
        description: '',
        activateRedirect: ''
    };

    let errorData = error.response ? error.response.data : null;

    if(errorData) {
        const {code, type, title, description, activateRedirect} = getErrorDetails(errorData) 
        errorResponse = {
            ...errorResponse,
            code,
            type,
            title,
            description,
            activateRedirect
        }
    }
    
    if(error.message && errorResponse.title === '') {
        errorResponse = {
            ...errorResponse,
            code: 'NA',
            type: 'error',
            title: error.message,
            description: error.message
        }
    }
    return errorResponse;
}

const getErrorDetails = error => {
    error = convertToLowercaseKeys(error);
    let title = error.errorsummary || '';
    let description = error.errorsummary || '';
    let type = 'error';
    let code = error.errorcode || '';
    let errorSummay = error.errorsummary.toLowerCase() || '';
    let activateRedirect = error.activateredirect || '';
    switch(code) {
        case 'E0000004': 
            if(errorSummay.includes('authentication failed')) {
                title = 'Sign in failed';
                //description = 'Invalid email or password. Please verify your account sign in information and try again.';
                //As per GS
                description = 'Your Logon ID or password is incorrect. Please try again.';
            }
            break;
        case 'E0000007':
            if(errorSummay.includes('resource not found')) {
                title = 'Sign In failed';
                //description = 'Invalid email or password. Please verify your account sign in information and try again.';
                description = 'Your Logon ID or password is incorrect. Please try again.';
            } else if(errorSummay.includes('user not found')) {
                title = '_';
                description = 'We are unable to validate your user credentials. Please check to ensure the information you entered is correct and try again.';
            }
            break;
        default:
            title = error.errorsummary || '';
            description = error.errorsummary || '';
            activateRedirect = error.activateredirect || '';
            break;
    }
    return { type, code, title, description, activateRedirect };
}

export const prepareRegisterRenderData = (schema, includeCredentialSchema, editMode) => {
    let formData = [];

    if( Object.keys(schema).length > 0) {
        // If Account Details Section Required
        if(includeCredentialSchema && Object.keys(credentialSchema).length > 0) {
            let credentialSchemaProps = credentialSchema.properties;
            if(editMode) {
                credentialSchemaProps = {
                    ...credentialSchemaProps, 
                    "RecoveryAnswer": {
                    "title": "SECURITY ANSWER",
                    "type": "password",
                    "mutability": "READ_WRITE",
                    "scope": "NONE",
                    "value":"••••••"        
                    }
                }
            }
            let updatedCredentialSchema = {
                properties: credentialSchemaProps
            };
            let accountDetails = prepareSectionData(updatedCredentialSchema, 'account');
            formData = [{...accountDetails}];
        }

        let schemaDefinitions = schema.definitions;
        let schemaDetails = Object.keys(schemaDefinitions).map(section => {
            return prepareSectionData(schemaDefinitions[section], section);
        })

        formData = [
            ...formData, 
            ...schemaDetails
        ];
    }

    return formData;
}

const prepareSectionData = (definition, groupName) => {
    let formDataObj = {};
    formDataObj.group = capitalizeFirstLetterforEachWord(groupName + ' details');

    formDataObj.data = [];

    let properties = definition.properties;
    formDataObj.data = Object.keys(properties).map(key => {
        let formObj = {};
        formObj.name = key;//.toLowerCase();
        formObj.label = properties[key].title;
        formObj.placeholder = '';
        formObj.type = properties[key].type;
        formObj.required = properties[key].required;
        formObj.mutability = properties[key].mutability;
        formObj.minLength = properties[key].minLength;
        formObj.maxLength = properties[key].maxLength;
        formObj.wrapperClass = '';
        formObj.labelClass = 'app-content-label';
        formObj.inputClass = 'app-content-text-width';
        return formObj;
    })
    return formDataObj;
}

export const prepareFormValuesObject = (formData, values) => {
    values = values ? values : {};

    let valuesObj = {};
    Object.keys(values).map(key => {
        //Profile schema camel case issue fix
        //let field = key.toLocaleLowerCase();
        let field = key;
        valuesObj[field] = values[key];
        return key;
    });
    let formDataObj = {};
    formData && formData.map(group => {
        group.data && group.data.map(item => {
            //Profile schema camel case issue fix
            //let field = item.name.toLowerCase();
            let field = item.name;
            formDataObj[field] = item.value || null;
            return item;
        })
        return group;
    });

    if(Object.keys(values).length > 0) {
        formDataObj = {...formDataObj, ...valuesObj}
    }
    
    return formDataObj;
}

export const convertToLowercaseKeys = data => {
    data = data ? data : {};
    let processedData = {};
    Object.keys(data).map(key => {
        let field = key.toLowerCase();
        processedData[field] = data[key];
        return key;
    })
    return processedData;
}

export const prepareRegisterSchema = (appSpecificSchema, schemaFromApi, includeCredentialSchema) => {
    
    const apiSchemaFields = getAllSchemaFields(schemaFromApi);
    let profileSchema = [];
    const isRegister = true;
    profileSchema = Object.keys(appSpecificSchema).map(group => {
        let fields = appSpecificSchema[group].map(name => {
            //Profile schema camel case issue fix
            //name = name.toLowerCase();
            //name = name;
            let field = apiSchemaFields[Object.keys(apiSchemaFields).find(key => key.toLowerCase() === name.toLowerCase())]; //apiSchemaFields[name];
            return prepareFieldObject(field, name, isRegister);
        })
        return {
            group,
            data: fields
        }
    });

    if(includeCredentialSchema) {

        let emailGroupIndex = -1;

        // finding the group index having email field
        profileSchema.map((g, i) => {
            let groupWithEmail = g.data.filter(item => item.name === 'email');
            if(groupWithEmail.length > 0) {
                emailGroupIndex = i
            }
            return g;
        })

        let passwordGroup = {
            group: 'Password Details',
            data: accountPasswordControls
        };

        // inserting the group just after the group with email
        profileSchema.splice((emailGroupIndex + 1), 0, passwordGroup);

        let securityGroup = {
            group: 'Security Details',
            data: securityQuestionControls
        }

        // inserting the security question group at the end
        profileSchema = [...profileSchema, securityGroup];
    }

    return profileSchema;
}

export const prepareProfileSchema = (appSpecificSchema, schemaFromApi, includeCredentialSchema) => {
    
    const apiSchemaFields = getAllSchemaFields(schemaFromApi);
    let profileSchema = [];
    const isRegister = false;
    profileSchema = Object.keys(appSpecificSchema).map(group => {
        let fields = appSpecificSchema[group].map(name => {
            //Profile schema camel case issue fix
            //name = name.toLowerCase();
            //name = name;
            let field = apiSchemaFields[Object.keys(apiSchemaFields).find(key => key.toLowerCase() === name.toLowerCase())]; //apiSchemaFields[name];
            return prepareFieldObject(field, name, isRegister);
        })
        return {
            group,
            data: fields
        }
    });

    if(includeCredentialSchema) {

        let hasAddedSecurity = false;

        // finding the group index having email field
        profileSchema = profileSchema.map(g => {
            if(g.group.toLowerCase() === 'account details') {
                g.data = [...g.data, ...profileAccountControls]
                hasAddedSecurity = true;
            }
            
            return g;
        })

        if(!hasAddedSecurity) {
            let securityGroup = {
                group: 'Account Details',
                data: profileAccountControls
            }

            profileSchema = [securityGroup, ...profileSchema];
        }
    }

    return profileSchema;
}

const getAllSchemaFields = (schema) => {
    schema = schema ? schema : {}
    let schemaDefinitions = schema.definitions || {};
    let schemaFields = {};
    Object.keys(schemaDefinitions).map(section => {
        let sectionFields = schemaDefinitions[section].properties;
        schemaFields = {...schemaFields, ...sectionFields};
        return section;
    });
    return schemaFields;
    //Profile schema camel case issue fix
    //return convertToLowercaseKeys(schemaFields);
}

export const getSchemaLabels = (schema) => {
    schema = schema ? schema : [];
    let schemaLabels = {}
    schema.map(group => {
        group.data.map(field => {
            let fieldObj = {};
            fieldObj[field.name] = field['label'];
            schemaLabels = {...schemaLabels, ...fieldObj};
            return field;
        })
        return group;
    })

    return schemaLabels
}

const prepareFieldObject = (field, name, isRegister) => {
    let fieldObj = {};
    let type = field.type;
    let min = field.minLength;
    let max = field.maxLength;
    let mutability = field.mutability;
    if(name === 'zipcode') {
        type = 'zip';
    } else if(name === 'mobilePhone' || name === 'primaryPhone') {
        type = 'phone';
    }else if ( name === 'countryCode'){
        type = 'countryCode';
    }else if(name === 'state' ){
        type = 'state';
    } 
    

    if(!isRegister && name === 'email') {
        mutability = 'READ';
    }
    //let type = field.title === 'Primary phone' || field.title === 'Mobile phone' ? 'phone' : field.type;
    // if(name === 'zipcode' || field.title === 'Zip code'){
    //     type = 'zip';
    //     min = 10000;
    //     max = 999999;
    // }else if(field.title === 'Primary phone'|| field.title === 'Mobile phone' ){
    //     type ='phone'
    // }else{
    //     type = field.type;
    // }

    //Profile schema camel case issue fix
    //fieldObj.name = name.toLowerCase();
    fieldObj.name = name;
    fieldObj.label = field.title;
    fieldObj.placeholder = '';
    fieldObj.type = type;
    fieldObj.required = field.required;
    fieldObj.mutability = mutability;
    fieldObj.minLength = min;
    fieldObj.maxLength = max;
    fieldObj.wrapperClass = '';
    fieldObj.labelClass = 'app-content-label';
    fieldObj.inputClass = 'app-content-text-width';
    
    let requiredFields = ["organization","streetAddress","city","countryCode","state","zipCode"]
    if(requiredFields.includes(name)){
        fieldObj.required = true;
    }

    return fieldObj;
}

export const getRequiredFieldNames = (schema) => {
    schema = schema ? schema : [];
    let reqFieldNames =[];
    schema.map(field=>{
      Object.keys(field).map(key => {
        field["data"].map(item =>{
            Object.keys(item).forEach(function (){
              if(item["required"] ===  true){
                if(reqFieldNames.indexOf(item["name"]) === -1){
                  reqFieldNames.push(item["name"]);
                }
              }
            })
            return item;
          })
          return key;
        });
      return field;
      })
      return reqFieldNames;
}

export const checkError = (reqFieldNames, data, schemaLabels) => {
    let error = {};
    // let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/g; 
    let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/g;
    Object.keys(data).forEach( function (name){
        if(reqFieldNames.includes(name)){
          let hasValid = false;
          let errorMsg = '';
          let title = schemaLabels[name];
          if(name === "email") {
            if(data[name] === ''){
                hasValid=false;
                errorMsg = "The Email address field cannot be empty.";
            }
            else if(checkIsInternalUser(data[name])){
                hasValid=false;
                errorMsg= INTERNAL_USER_RESTRICTION_MESSAGE;
            }
            else{
                hasValid = validator.isEmail(data[name] || '');
                errorMsg = "The email entered is not in a valid format.";

            }
            
          } 
          else if(name === "recoveryanswer" && !validator.isEmpty(data[name] || '')){
            let legnth= parseInt((data[name] || '').length);
            if(legnth<4) {
                hasValid = false;
                errorMsg = "The answer must be at least 4 characters in length.";
            } 
            else if(legnth>50) {
                hasValid = false;
                errorMsg = "The answer must not be more than 50 characters in length.";
            }
            else {
                hasValid = true;
            }
          }
          else if(name === "mfaanswer" && !validator.isEmpty(data[name] || '')){
            let legnth= parseInt((data[name] || '').length);
            if(legnth<4) {
                hasValid = false;
                errorMsg = "The answer must be at least 4 characters in length.";
            } 
            else if(legnth>50) {
                hasValid = false;
                errorMsg = "The answer must not be more than 50 characters in length.";
            }
            
            else {
                hasValid = true;
            }
          }
          else if(name === "password" && !validator.isEmpty(data[name] || '')){
            let metPattern= passwordPattern.test(data[name]);
            // let username_part = data['email'].split(/[.\-_@]/);
            // username_part.map((part)=>{
            //     if(data[name].includes(part)){
            //         hasValid= false;
            //         errorMsg = "Password cannot contain part of username";
            //     }
            // });
            if(!metPattern) {
                hasValid = false;
                errorMsg = "Please Check the password pattern";
            } else {
                hasValid = true;
            }
          }
           else if(name === "confirm_password" && !validator.isEmpty(data[name] || '')){
            hasValid = data['password'] === data['confirm_password'];
            errorMsg = "The passwords entered do not match.";
          } 
          else if(name === "countryCode"){
            hasValid = !validator.isEmpty(data[name] || '');
            errorMsg = "Country / Region field cannot be empty";
          }
          else if(name === "state"){
            hasValid = !validator.isEmpty(data[name] || '');
            errorMsg = "State / Province field cannot be empty";
          }
          else {
            hasValid = !validator.isEmpty(data[name] || '');
            errorMsg = title + " field cannot be empty";
          }
  
          if(hasValid) {
              delete error[name];
          } else {
              error[name] = errorMsg;
          } 
        }
      })
    return error;
}

// export const checkIsInternalUser = email => {
//     email =email ?email:'';
//     return email.toLowerCase().includes(INTERNAL_EMAIL_DOMAIN);
// }

//New SSO
export const checkIsInternalUser = email => {
    email =email ?email:'';
    let isInternal =  email.toLowerCase().includes(INTERNAL_EMAIL_DOMAIN);
    if(!isInternal){
        isInternal =  email.toLowerCase().includes(INTERNAL_DEV_EMAIL_DOMAIN);
    }
    return isInternal;
}


export const getOktaUrls = (email, application) => {
    let isInternalUser = checkIsInternalUser(email);
    let oktaUrl;
    let sandboxUrl;
    if(isInternalUser) {
        oktaUrl = application.internal_okta_url;
       // sandboxUrl = application.external_okta_sandbox_url;
    } else {
        oktaUrl = application.external_okta_url;
        //sandboxUrl = application.external_okta_sandbox_url;
    }

    oktaUrl = oktaUrl.trim();
    //sandboxUrl = sandboxUrl.trim();

    return {
        oktaUrl //, sandboxUrl
    }
}

export const getBaseUrl = url => {
    const urlSplits = url.split('/');
    return urlSplits[0] + '//' + urlSplits[2];
}

export const generateRandomNumber = length => {
    var crypto = window.crypto || window.msCrypto;
    const rNum = crypto.getRandomValues(new Uint8Array(1))[0];
    return parseInt(rNum * (10 ** (length - rNum.toString().length)).toFixed());
}

export const parseJwt = token => {
    let jsonPayload = '{}';

    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    } catch(ex) {
        // Silently ignore
    }
    
    return JSON.parse(jsonPayload);
};

export const packEmail = (email) => {
    return window.btoa(email.split('').reverse().join(''))
}

export const unpackEmail = (text) => {
    return window.atob(text).split('').reverse().join('');
}

export const prepareAuthResponse = response => {
    let user = null;

    if(response.hasOwnProperty('FirstName')) {
        user = response
    } else {
        try {
            user = {};
            user["SessionToken"] = response.sessionToken;
            user["ExpiresAt"] = response.expiresAt;
            user["Status"] = response.status;
            user["FirstName"] = response._embedded.user.profile.firstName;
            user["LastName"] = response._embedded.user.profile.lastName;
            user["Login"] = response._embedded.user.profile.login;
            user["Password"] = null;
            user["EmrValidationStatus"] = 'VALID';
        } catch(ex) {
            // Silently ignore
        }
    }

    return user;
}