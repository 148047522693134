import React, { Component } from 'react';
import PropTypes from "prop-types";
import validator from 'validator';
import Grid from '@material-ui/core/Grid';

import MessageComponent from './message-component';
import LoginFormComponent from './login-form';
import OktaRedirect from './redirect';
import { KEY_ENTER, KEY_TAB } from '../../utils/constants';
import { checkIsInternalUser, getOktaUrls, getBaseUrl, generateRandomNumber } from '../../utils/helper';
import { REACT_SSO_AUTHORIZATION_PATH } from '../../api/constants';

class Login extends Component {
    state = {
        error: {},
        credential: {
            username: '',
            sec: ''
        },
        message: {
            type: 'info',
            header: 'Login Failed',
            description: 'Enter correct credentials'
        },
        redirectUrl: null
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate = (prevProps) => {
        const { profile } = this.props;
        
        
        if(prevProps.profile !== profile) {
            // Got user profile, can redirect to landing page
            this.updateRedirectUrl();
        }
    }

    render() {
        const { authError } = this.props;
        const { credential, error, redirectUrl } = this.state;
        const apiError = Object.keys(authError).length > 0 ? authError : null;
        
        return ( 
            <div className="app-container"> 
                {  
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                }
                <Grid container spacing={0}>
                    <Grid item md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <div className="app-content" >
                                <h2 className="app-content-h2"> Tools for Your Application Needs  </h2> 
                                <h3 className="app-content-h3"> Sign in or register once to access
                                all of your Emerson accounts. 
                                </h3>
                                <h5 className="app-content-h5">
                                    Get access to Emerson’s Tools using just one username and one password with our new seamless platform. While we build, please note that you may still be required to use existing usernames and passwords
                                    for some web applications.
                                </h5>
                                {
                                    apiError && (
                                        <MessageComponent message = {authError}/>
                                    )
                                }
                                
                            </div> 
            
                            <LoginFormComponent 
                                onSignIn = {this.submitForm}
                                onChange = {this.handleChange}
                                data={credential} 
                                handleKeyPress = {this.handleKeyPress} 
                                handleRouteToFrgtPswd = {this.handleRouteToFrgtPswd}
                                handleRouteToRegistration = {this.handleRouteToRegistration}
                                error={error}
                                apiError={apiError} /> 
                        </Grid >
                    <Grid item md={2}> </Grid> 
                </Grid >
            </div>
        );
    }

    updateRedirectUrl = () => {
        const { profile, application } = this.props;
        const { credential } = this.state;
        let client_id = '';
        let app_redirect_url = application.app_redirect_url;
        let response_type = '';
        let nonce = '';
        const { location } = this.props;
        let search = location.search;
        let params = new URLSearchParams(search);
        const state = params.get('state');
        
        const isInternalUser = checkIsInternalUser(credential.username);
        if(isInternalUser) {
            client_id = application.internal_client_id;
        } else {
            client_id = application.external_client_id;
        }
        response_type = 'code token id_token';
        nonce = `&nonce=${generateRandomNumber(7)}`;
        let oktaUrls = getOktaUrls(credential.username, application);
        let baseUrl = getBaseUrl(oktaUrls.oktaUrl);
    
        let authorizationUrl=baseUrl+REACT_SSO_AUTHORIZATION_PATH;

        let redirectUrl = `${authorizationUrl}?`;
            redirectUrl = `${redirectUrl}client_id=${client_id}`;
            redirectUrl = `${redirectUrl}&scope=openid profile email`;
            redirectUrl = `${redirectUrl}&response_type=${response_type}`;
            redirectUrl = `${redirectUrl}&response_mode=form_post`;
            redirectUrl = `${redirectUrl}&sessionToken=${profile.sessiontoken}`;
            redirectUrl = `${redirectUrl}&redirect_uri=${app_redirect_url}`;


           

            if(state){
                redirectUrl = `${redirectUrl}&state=${state}`;
            }
            else{
                redirectUrl = `${redirectUrl}&state=login`;
                redirectUrl = `${redirectUrl}/${credential.username}`;
            }


            
            redirectUrl = `${redirectUrl}${nonce}`;
        
        redirectUrl = encodeURI(redirectUrl);

        this.setState({redirectUrl})
    }

    handleChange = event => {
        let error = Object.assign({}, this.state.error);
        const { message, clearAuthenticateError } = this.props;

        const name = event.target.name;
        const value = event.target.value;
        let credential = Object.assign({}, this.state.credential);
        credential[name] = value;
        this.setState({ credential }, () => {
            if (message) {
                clearAuthenticateError();
            }
            if(error[name]) {
                let newError = this.getFormErrors(name, credential);
                this.setState({error: newError})
            }
        });
    };

    handleRouteToFrgtPswd = () => {
        const { history, application, clearAuthenticateError } = this.props;
        clearAuthenticateError();
        history.push(`/${application.app_id}/forgot-password`)
    }

    handleRouteToRegistration = () => {
        const { history, application, clearAuthenticateError } = this.props;
        clearAuthenticateError();
        history.push(`/${application.app_id}/registration`)
    }

    getFormErrors = (name, credential) => {
        let error = Object.assign({}, this.state.error);
        let fields = Object.keys(credential).filter(f => name === null || f === name);
        
        fields.map(name => {
            let hasValid = false;
            let errorMsg = '';
            if(name === "username") {
                hasValid = validator.isEmail(credential[name] || '');
                errorMsg = "Please enter a valid email format";
            } else if(name === "sec") {
                hasValid = !validator.isEmpty(credential[name] || '');
                errorMsg = "Password field cannot be empty";
            }

            if(hasValid) {
                delete error[name];
            } else {
                error[name] = errorMsg;
            }

            return name;
        });
        
        return error;
    }

    submitForm = e => {
        e.preventDefault();

        let credential = Object.assign({}, this.state.credential);
        let error = this.getFormErrors(null, credential);

        this.setState({error}, () => {
            if(Object.keys(error).length === 0) {
                const { authenticateUser, application } = this.props;
                const isInternalUser = checkIsInternalUser(credential.username);
                let oktaUrls = getOktaUrls(credential.username, application);
                let url = '';
                const data = {
                    Login: credential.username,
                    Password: credential.sec
                }
                // if(isInternalUser) {
                //     url = oktaUrls.oktaUrl;
                //     data = {
                //         username: credential.username,
                //         password: credential.sec
                //     }
                // } else {
                //     url = oktaUrls.sandboxUrl + 'user/';
                //     data = {
                //         Login: credential.username,
                //         Password: credential.sec
                //     }
                // }
                
                authenticateUser(application.app_id, data);
            }
        });
    }

    handleKeyPress = (event) => {
        if (event.key === KEY_ENTER) {
            this.submitForm(event);
        } else if(event.key === KEY_TAB) {
            const name = event.target.name;
            let error = this.getFormErrors(name, this.state.credential);
            this.setState({error})
        }
    }
}

Login.propTypes = {
    message: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    authenticateUser: PropTypes.func.isRequired,
    clearAuthenticateError: PropTypes.func.isRequired,
    authError: PropTypes.object,
    application: PropTypes.object.isRequired,
    history: PropTypes.object,
};

export default (Login);