import { axiosOktaInstance, axiosOktaExternalBase } from "./constants";
import { prepareErrorResponse, prepareProfileSchema, prepareRegisterSchema } from "../utils/helper";

export default class ProfileApi {
    static getProfileSchema = (url,appSpecificSchema, includeCredentialSchema, editMode, isRegister) => {
        return axiosOktaInstance.get(url + 'user/profile/schema')
            .then(response => {
                let profileData = isRegister
                ? prepareRegisterSchema(appSpecificSchema, response.data, includeCredentialSchema)
                : prepareProfileSchema(appSpecificSchema, response.data, includeCredentialSchema)
                
                return profileData;
            })
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static getProfile = (url, email, idToken) => {
        //return axiosOktaInstance.get(url + `user/${email}`)
        let token=localStorage.getItem('idToken');
        if(token){
            axiosOktaInstance.defaults.headers.common['Authorization'] = "Bearer "+token ;
        }
        return axiosOktaInstance.get(url + `user/user-profile/${email}`)
            .then(response => response.data)//  .then(response => convertToLowercaseKeys(response.data)) //Profile schema camel case issue fix
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static getForgotPasswordRecToken = (url, data) => {
        return axiosOktaInstance.post(url + 'user/forgot_password', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    } 

    static getForgotPasswordSecQuestion = (url, data) => {
        //return axiosOktaInstance.post(url + 'user/verify/recovery_token', data)
        return axiosOktaInstance.post(url + 'user/get-recovery-question', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    } 

    static resetPasswordWithSecAnswer = (url, data) => {
        return axiosOktaInstance.post(url + 'user/reset_password', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static changePassword = (url, data) => {
        let token=localStorage.getItem('idToken');
        if(token){
            axiosOktaInstance.defaults.headers.common['Authorization'] = "Bearer "+token ;
        }
        return axiosOktaInstance.post(url + 'user/change_password', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static changePasswordSecQuestionAnswer = (url, data) => {
        return axiosOktaInstance.post(url + 'user/change_recovery_question', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static getEditProfileSecQuestion = (url, data) => {
        let token=localStorage.getItem('idToken');
        if(token){
            axiosOktaInstance.defaults.headers.common['Authorization'] = "Bearer "+token ;
        }
        
        return axiosOktaInstance.post(url + '/mfa/challenge', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    } 

    static verifyEditProfileSecAnswer = (url, data) => {
        let token=localStorage.getItem('idToken');
        if(token){
            axiosOktaInstance.defaults.headers.common['Authorization'] = "Bearer "+token ;
        }
        return axiosOktaInstance.post(url + '/mfa/verify', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static getUserMfaQuestions = (url, data) => {
        return axiosOktaInstance.get(url + `/mfa/questions/${data.username}`)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static createUser = (url, data) => {
        return axiosOktaInstance.post(url + 'user/register', data)
                .then(response => response.data)
                .catch(error => {
                    throw prepareErrorResponse(error);
                });
    }

    static activateUser = (url, data) => {
        return axiosOktaInstance.post(url + 'user/activate', data)
                .then(response => response.data)
                .catch(error => {
                    throw prepareErrorResponse(error);
                });
    }

    static saveUser = (url, data) => {
        let token=localStorage.getItem('idToken');
        if(token){
            axiosOktaInstance.defaults.headers.common['Authorization'] = "Bearer "+token ;
        }
        return axiosOktaInstance.post(url + '/user/update', data)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }

    static getUserSession = (url) => {
        return axiosOktaExternalBase.get(url + 'sessions/me')
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }
}