import initialState from '../initialState';
import { 
    AUTHENTICATE_USER, AUTHENTICATE_USER_SUCCESS, AUTHENTICATE_USER_FAILED, 
    CLEAR_AUTHENTICATE_ERROR_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILED,
} from '../actionTypes';
import { STATUS_PROGRESS, STATUS_SUCCESS, STATUS_FAILED } from '../../utils/constants';

const authenticateReducers = (state = initialState.auth, action) => {
    switch(action.type) {
        // Authenticate user while login
        case AUTHENTICATE_USER:
        case LOGOUT_USER:
            return {
                ...state,
                status: STATUS_PROGRESS
            };
        case AUTHENTICATE_USER_SUCCESS: 
            return {
                ...state,
                profile: action.profile,
                status: STATUS_SUCCESS
            };
        case AUTHENTICATE_USER_FAILED:
        case LOGOUT_USER_FAILED:
            return {
                ...state,
                error: action.error,
                status: STATUS_FAILED
            };
        
        // Clear authenication error while retyping credentials
        case CLEAR_AUTHENTICATE_ERROR_SUCCESS:
            return {
                ...state,
                error: {}
            };
        
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                status: STATUS_SUCCESS
            };

        default:
            return state
    }
}

export default authenticateReducers;
