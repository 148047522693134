import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, withStyles, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const styleSheet = {
  list : {
    width : 200,
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
  },
  smallLogo:{
    width:"90px",
    padding: "5px 10px",
  },
  smallLogo_emerson:{
    width:"90px",
    padding: "5px 10px",
    height:"60px",
  },
  applogo:{
    maxWidth:"100%",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)"
  }, 
  fullsizeLogo:{
    width:"94px",
    padding: "5px 15px"
  },
  fullsizeLogo_emerson:{
    width:"94px",
    padding: "5px 15px",
    height: "60px"
  },
  sideBarIcon : {
    padding : 0,
    color : "#3f51b5",
    cursor : "pointer",
  },
  appHeader : {
    background: "#FFF !important",
    color: "#000 !important",
    padding:"4px !important",
    height:"75px"
  },
  userAccount:{
    padding: "10px"
  },
  userIcon:{
    fontSize:"40px",
    color: "#144a86"
  },
  logoSection:{
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap"
  }
}

class Header extends Component{
  state = {};
 
  constructor(props){
    super(props);
    this.state = {drawerActivate:false, drawer:false};
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  UNSAFE_componentWillMount(){
    if(window.innerWidth <= 600){
      this.setState({drawerActivate:true});
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 600){
        this.setState({drawerActivate:true});
      }
      else{
        this.setState({drawerActivate:false})
      }
    });
  }

  //Small Screens
  createDrawer(logo, applogo){
    return (
      <div>
        <AppBar className={this.props.classes.appHeader}>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <div  className={this.props.classes.logoSection}>
                 <img  className={this.props.classes.smallLogo_emerson}
                 style={{ borderRight: '1px solid #eee' }}
                 src={logo} alt="" />
                 {
                   applogo &&
                   <div className={this.props.classes.smallLogo}>
                   <img className={this.props.classes.applogo} src={applogo} alt="" />
                   </div>
                 }
              </div>
              <MenuIcon
                className = {this.props.classes.sideBarIcon}
                onClick={()=>{this.setState({drawer:true})}} />
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  //Larger Screens
  destroyDrawer(logo, applogo){
    const {classes} = this.props;

    return (
      <AppBar className={classes.appHeader} style={{boxSizing: "border-box"}}>
        <Toolbar>
          <div style={{flexGrow:1}} color="inherit" >
            <div className={this.props.classes.logoSection}>
              <img className={this.props.classes.fullsizeLogo_emerson}
                style={{ borderRight: '1px solid #eee' } }
                src={logo} alt="" />
              {
                applogo &&
                <div className={this.props.classes.fullsizeLogo}>
                  <img className={this.props.classes.fullsizeLogo} src={applogo} alt="" />
                </div>
              }
            </div>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  render() {
    const logo = process.env.PUBLIC_URL + '/images/emerson-logo-data-404.png';
    const { application } = this.props;
    const applogo = application ? application.app_logo : '';

    return(
      <div>
        { 
          this.state.drawerActivate 
          ? this.createDrawer(logo, applogo) 
          : this.destroyDrawer(logo, applogo)
        }
      </div>
    );
  }
}

Header.propTypes = {
  classes : PropTypes.object.isRequired,
  application: PropTypes.object,
};



export default withStyles(styleSheet)(withRouter(Header));