import {connect} from "react-redux";
import Login from './Login';
import { 
    actSSOAuthenticateUser, actClearAuthenticateError 
} from '../../store/actions/authenticateActions';

const mapStateToProps = state => {
    return {
        message: prepareLoginMessage(state.auth.error),
        profile: state.auth.profile,
        status: state.auth.status,
        authError: state.auth.error,
        application: state.admin.application,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (url, data) => dispatch(actSSOAuthenticateUser(url, data)),
        clearAuthenticateError: payload => dispatch(actClearAuthenticateError(payload))
    }
}

const prepareLoginMessage = error => {
    let message = {};
    
    if(error && Object.keys(error).length > 0 ) {
        const hasError = error.code ? true : false;
        //let description = error.code === "E0000004" ? 'Please verify your credentials': 'Something went wrong. Please try again.';
        if(hasError) {
            message = {
                type: error.type,
                title: error.title,
                description: error.description
            }
        }
    }
    return message;
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);