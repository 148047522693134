import React from 'react';
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import SubmitIcon from "@material-ui/icons/KeyboardArrowRight";
import PasswordStrengthIndicator from '../../shared/PasswordStrengthIndicator';
import validator from 'validator';
import notify from '../../../utils/notify';
import { STATUS_FAILED, STATUS_VERIFIED, ENABLE_ERROR_NOTIFIER, KEY_ENTER, KEY_TAB } from '../../../utils/constants';
import { getOktaUrls, unpackEmail } from '../../../utils/helper';
import ErrorMesasge from '../../shared/ErrorMessage';
import PasswordInput from '../../shared/PasswordInput';
import TextInput from '../../shared/TextInput';
import { SSO_ADMIN_PUB_URL } from '../../../api/constants';
 
class ConfirmPwd extends React.Component {
  state = {
    message:'',
    error:[],
    hasProfileError:false,
    credentials: {
      fpAnswer: "",
      sec: "",
      confirmSec: ""
    },
    url: null,
  };

  componentDidMount = () => {
    const { getRecoveryQuestion, match, application } = this.props;
    const { id } = match.params;
    const {token} = match.params;
    const login = unpackEmail(id);
    const Recoverytoken = token;
    let payload = { "Login": login, "RecoveryToken": Recoverytoken };
    let oktaUrls = getOktaUrls(login, application);
    //let url = oktaUrls.sandboxUrl;
    const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
    
    this.setState({ url }, () => {
      getRecoveryQuestion(url, payload);
    })
    
  }

  componentDidUpdate = prevProps => {
    const { status, error, history, application, activateRedirect} = this.props;
    if(prevProps.error !== error) {
      if(ENABLE_ERROR_NOTIFIER){
        this.setState({hasProfileError:false,message:''}) ;
        notify({message: error.description}, 'error');
      }else {
          this.setState({hasProfileError:true,message:error}) 
      }
    }

    if(prevProps.status !== status) {
      
      if(status === STATUS_FAILED) { 
        if(ENABLE_ERROR_NOTIFIER){
          this.setState({hasProfileError:false,message:''}) ;
          notify({message: error.description}, 'error');
        }else {
            this.setState({hasProfileError:true,message:error}) 
        }
      }
      if(status === STATUS_VERIFIED) {

        if(ENABLE_ERROR_NOTIFIER){
          this.setState({hasProfileError:false,message:''}) ;
          
        } else {
            this.setState({hasProfileError:true,message:'Password has been changed successfully'}) 
        } 
        notify({message: 'Password has been changed successfully'}, 'success');
        history.push(`/${application.app_id}/${activateRedirect}`);
      }
    //   if(status === STATUS_SUCCESS) {
    //     this.setState({hasProfileError:false}) ;
    // }
  }
}

  render() {
    const inputClass = "app-content-text-width";
    const errorInputClass = "app-content-text-width-with-error";
    const { hasProfileError, message } = this.state;
    let error = Object.assign({}, this.state.error);
    let credentials = Object.assign({}, this.state.credentials);
    let { recovery } = typeof(this.props.recovery) === "undefined"  ? {recovery:{}}:this.props;
    
    
    return (
      <div className="app-container-fp">
        <Container maxWidth="sm" spacing={5} className="app-content-padding">
          <Card className="shadow-bordered-container">
            <CardContent>
              <br />
              <Grid container spacing={3} className="">
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Typography className="app-content-h2 app-content-left" component="h5" variant="h5">Reset Your Password</Typography>
                </Grid>
              </Grid>
              
              <Grid container spacing={3} className="">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography className="app-content-h4">Please answer your Security Question,
                    then create a new password for your My Emerson account.                                   
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={3} className="">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography className="app-content-h4 app-content-label">
                      Security Question:
                    </Typography>
                    <Typography className="app-content-h4" >
                      {recovery.RecoveryQuestion}                                  
                    </Typography>
                  </Grid>
                </Grid>

              <Grid container spacing={3} className="">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                      { hasProfileError && <ErrorMesasge message={message}/> }
                  </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <form className="">
                <Grid container spacing={2}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <PasswordInput wrapperClass=""
                      wrapperId="txtSecAns"
                      labelClass="app-content-label"
                      label="SECURITY ANSWER"
                      error={error.fpAnswer}
                      errorClass="emr-field-error-message"
                      inputClass={error.fpAnswer ? errorInputClass : inputClass}
                      type="password"
                      name="fpAnswer"
                      value={credentials.fpAnswer}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                      autofocus={true}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <PasswordInput wrapperClass=""
                      wrapperId="createPassword"
                      labelClass="app-content-label"
                      label="Create New Password"
                      error={error.sec}
                      errorClass="emr-field-error-message"
                      inputClass={error.sec ? errorInputClass : inputClass}
                      type="password"
                      name="sec"
                      value={credentials.sec}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <PasswordInput wrapperClass=""
                      wrapperId="confirmSec"
                      labelClass="app-content-label"
                      label="Confirm New Password "
                      error={error.confirmSec}
                      errorClass="emr-field-error-message"
                      inputClass={error.confirmSec ? errorInputClass : inputClass}
                      type="password"
                      name="confirmSec"
                      value={credentials.confirmSec}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <PasswordStrengthIndicator                  
                      password={credentials.sec}                      
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} className="">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <MuiThemeProvider>
                      <ButtonBase
                        className="app-button-primary"
                        variant="contained"
                        color="primary"
                        onClick={() => this.submitForm()}
                      >
                        Submit<SubmitIcon  />
                      </ButtonBase>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <MuiThemeProvider>
                      <ButtonBase
                        className="app-button-cancel"
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.handleRoute(e)}
                      >
                        CANCEL
                        </ButtonBase>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
              </form>
              <br />
            </CardContent>
          </Card>
        </Container>
      </div>
    );
  }

  handleRoute = e => {
    e.preventDefault();

    const { application } = this.props;
    this.props.history.push(`/${application.app_id}/forgot-password`);
  }

  handleChange = event => {
    let error = Object.assign({}, this.state.error);
    const name = event.target.name;
    const value = event.target.value;
    let credentials = Object.assign({}, this.state.credentials);
    credentials[name] = value;
    this.setState({ credentials }, () => {
      if(error[name]) {
        let newError = this.getFormErrors(name, credentials);
        this.setState({error: newError})
      }
    });
  };

  getFormErrors = (name, credentials) => {
    let error = Object.assign({}, this.state.error);
    let fields = Object.keys(credentials).filter(f => name === null || f === name);
    let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g; 

    fields.map(name => {
        let hasValid = false;
        let errorMsg = '';
        if(name === "fpAnswer") {
          hasValid = !validator.isEmpty(credentials[name] || '');
          errorMsg = "Security Answer field cannot be empty";
        } else if(name === "sec") {
          hasValid = !validator.isEmpty(credentials[name] || '');
          errorMsg = "Password field cannot be empty";
          if(hasValid) {
            let metPattern= passwordPattern.test(credentials[name]);
            if(!metPattern) {
              hasValid = false;
              errorMsg = "Please Check the password pattern";
            }
          }
        } else if(name === "confirmSec") {
          hasValid = !validator.isEmpty(credentials[name] || '');
          errorMsg = "Confirm Password field cannot be empty";
          if(hasValid && (credentials['sec'] !== credentials['confirmSec'])) {
            hasValid = false;
            errorMsg = "The passwords entered do not match.";
          }
        }
      if(hasValid) {
        delete error[name];
      } else {
        error[name] = errorMsg;
      }
      return name;
    });
    return error;
}

handleKeyPress = event => {
  if (event.key === KEY_ENTER) {
    this.submitForm();
  } else if(event.key === KEY_TAB) {
    const name = event.target.name;
    let error = this.getFormErrors(name, this.state.credentials);
    this.setState({error})
  }
}

submitForm = () => {
  const { match } = this.props;
  const { id } = match.params;
  const token = this.props.recovery;
  const login = unpackEmail(id);

  let credentials = Object.assign({}, this.state.credentials);
  let error = this.getFormErrors(null, credentials);
  
  this.setState({error}, () => {
    if(Object.keys(error).length === 0) {
      const { resetPasswordWithSecAnswer } = this.props;
      const { url } = this.state;
      let data = {
        Login: login,
        NewPassword: credentials['sec'],
        RecoveryAnswer: credentials['fpAnswer'],
        StateToken: token.StateToken
      };
      resetPasswordWithSecAnswer(url, data);
    }
  });
}

}
ConfirmPwd.propTypes = {
  getRecoveryQuestion: PropTypes.func.isRequired,
  resetPasswordWithSecAnswer: PropTypes.func.isRequired,
  match: PropTypes.object,
  application: PropTypes.object,
  status: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  recovery: PropTypes.object.isRequired,
};

export default (ConfirmPwd);