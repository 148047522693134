import { axiosOktaExternalBase, axiosSsoAdminInstance, 
    // axiosSsoAdminInstance 
} from './constants';
import { prepareErrorResponse, convertToLowercaseKeys, prepareAuthResponse } from '../utils/helper';

export default class AuthenticationApi {
    static authenticateUser = (app_id, credential) => {
        return axiosSsoAdminInstance.post(`/pub/${app_id}/common/user/authn`, credential) 
            .then(response => {
                let user = prepareAuthResponse(response.data);
                if(user) {
                    return convertToLowercaseKeys(user)
                } else {
                    throw new Error('Authentication failed');
                }
            })
            .catch(error => {
                throw prepareErrorResponse(error);
            })
    }


    //NEW SSO_LOGIN
    static ssoauthenticateUser = (app_id, credential) => {
        return axiosSsoAdminInstance.post(`/pub/${app_id}/common/user/ssoauthn`, credential) 
            .then(response => {
                let user = prepareAuthResponse(response.data);
                if(user) {
                    return convertToLowercaseKeys(user)
                } else {
                    throw new Error('Authentication failed');
                }
            })
            .catch(error => {
                throw prepareErrorResponse(error);
            })
    }



    static logoutUser = (url) => {
        return axiosOktaExternalBase.delete(url + 'sessions/me') 
            .then(response => convertToLowercaseKeys(response.data))
            .catch(error => {
                throw prepareErrorResponse(error);
            })
    }
}