import authenticationApi from '../../api/authenticationApi';

import { 
    AUTHENTICATE_USER, AUTHENTICATE_USER_SUCCESS, AUTHENTICATE_USER_FAILED, 
    CLEAR_AUTHENTICATE_ERROR_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILED,
} from '../actionTypes';


// Exposed to components for calling
export const actAuthenticateUser = (url,credential) => {
    return dispatch => {
        dispatch(actAuthenticateUserInit());
        return authenticationApi.authenticateUser(url,credential)
            .then(response => dispatch(actAuthenticateUserSuccess(response)))
            .catch(error => dispatch(actAuthenticateUserFailed(error)))
    }
}


//NEW SSOLOGIN
export const actSSOAuthenticateUser = (url,credential) => {
    return dispatch => {
        dispatch(actAuthenticateUserInit());
        return authenticationApi.ssoauthenticateUser(url,credential)
            .then(response => dispatch(actAuthenticateUserSuccess(response)))
            .catch(error => dispatch(actAuthenticateUserFailed(error)))
    }
}


const actAuthenticateUserInit = () => {
    return {
        type: AUTHENTICATE_USER
    }
}

// Not exposed to outside, will call internally if success
const actAuthenticateUserSuccess = profile => {
    return {
        type: AUTHENTICATE_USER_SUCCESS,
        profile
    }
}

// Not exposed to outside, will call internally if failed
const actAuthenticateUserFailed = error => {
    return {
        type: AUTHENTICATE_USER_FAILED,
        error
    }
}

export const actClearAuthenticateError = () => {
    return {
        type: CLEAR_AUTHENTICATE_ERROR_SUCCESS
    }
}

export const actLogoutUser = (url) => {
    return dispatch => {
        dispatch(actLogoutUserInit());
        return authenticationApi.logoutUser(url)
            .then(response => dispatch(actLogoutUserSuccess(response)))
            .catch(error => dispatch(actLogoutUserFailed(error)))
    }
}

const actLogoutUserInit = () => {
    return {
        type: LOGOUT_USER
    }
}

// Not exposed to outside, will call internally if success
const actLogoutUserSuccess = profile => {
    return {
        type: LOGOUT_USER_SUCCESS,
        profile
    }
}

// Not exposed to outside, will call internally if failed
const actLogoutUserFailed = error => {
    return {
        type: LOGOUT_USER_FAILED,
        error
    }
}