import React from 'react';
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const ProfileHeader = ({basicData}) => {
    basicData = basicData ? basicData : {};
    return (
        <Grid container className="profile-header-container">
            <Grid container item xs={12} md={6}>
                <Grid item xs={12} md={10} className='profile-info-container'>
                    <div className="profile-header-title">{basicData.firstname}</div>
                    <div className="profile-header-sub-title">{basicData.email}</div>
                    <div className="profile-header-description">
                        <span>Member since </span>
                        <Moment format="MMM DD, YYYY">{basicData.created}</Moment>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
                <Grid item xs={12} className="profile-link-container">
                    <Link className="profile-link" style={{display:"none"}}>
                        Request Email Change
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

ProfileHeader.propTypes = {
    basicData: PropTypes.object.isRequired,
}

export default ProfileHeader;