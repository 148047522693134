import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, Grid } from '@material-ui/core';
import zxcvbn from 'zxcvbn';
const Strength = (props) => {
    let Strength = withStyles({
        root: {
            height: 10,
            backgroundColor: props.backgroundColor,
        },
        bar: {
            backgroundColor: props.barcolor,
        },
    })(LinearProgress);
    return <Strength {...props} />
};

Strength.propTypes = {
  backgroundColor: PropTypes.string,
  barcolor: PropTypes.string,
}

class PasswordStrengthIndicator extends Component {
    createPasswordLabel = (result) => {
        switch (result.score) {
          case 0:
            return 'Weak';
          case 1:
            return 'Weak';
          case 2:
            return 'Fair';
          case 3:
            return 'Good';
          case 4:
            return 'Strong';
          default:
            return 'Weak';
        }
      }
    render() {
        const testedResult = zxcvbn(this.props.password); 
        const strengthColors = ["#F25F5C", "#efca46","#247BA0","#00aa7e"];
        const textColor = strengthColors[testedResult.score === 0 
          ? testedResult.score : testedResult.score-1];
        return (<div>
            <Grid container>
                <Grid item xs={12} md={12} container
                  direction="row"
                  justify="space-between"
                  alignItems="center">
                  <label className="app-content-label">PASSWORD STRENGTH</label>
                  <label className="app-content-label-red" style={{color: textColor}}>
                    {this.createPasswordLabel(testedResult)}
                  </label>
                </Grid>
                <Grid item xs={false}  md={12}>
                  
                </Grid>
                <Grid item  xs={12} md={12}>
                <Strength
                variant="determinate"
                value={testedResult.score*25}
                barcolor={textColor}
            />
                </Grid>

            </Grid>
            
            <Typography className="app-content-h4" variant="caption">
              Password must be a minimum of eight characters and contain at least one number, 
              one lowercase and one uppercase letter. Must not contain your email or parts of
              your email separated by the following characters: , . _ # @.
            </Typography>
        </div >)
    }
  }

PasswordStrengthIndicator.propTypes = {
  password: PropTypes.string
}

export default PasswordStrengthIndicator;