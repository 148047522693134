import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Grid } from '@material-ui/core';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Footer = ({wrapperClass}) => {
  // const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <footer className={wrapperClass}>
      <Grid container>
        <Grid item xs={12} className="ft-copyright-container">
          <span className="ft-copyright">&copy;{year} Emerson. All Rights Reserved.</span>
        </Grid>
        <Grid container item xs={12} >
          <Grid item xs={4} md={3} lg={2}>
            <ListItemLink 
              className="nav-link nav-link-right"
              href="https://www.emerson.com/en-in/privacy-notice">
              Privacy Notice
            </ListItemLink>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <ListItemLink
              className="nav-link nav-link-right"
              href="https://www.emerson.com/en-in/terms-of-use">
              Terms of Use
            </ListItemLink>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <ListItemLink 
              className="nav-link"
              href="https://www.emerson.com/en-in/imprint">
              Other
            </ListItemLink>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  wrapperClass: PropTypes.string
}

export default Footer;