import { connect } from 'react-redux';
import StatusPage from './StatusPage';
import { actClearIdTokenFromLocalStorage } from '../../store/actions/storageActions';

const mapStateToProps = (state) => {
    return {
        application: state.admin.application,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        clearIdTokenFromLocalStorage: payload => dispatch(actClearIdTokenFromLocalStorage(payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusPage);