import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import IdpbRedirect from './components/sso-idpb/Idpb-redirect'

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/sso/session/:tenant" render={(props) => <IdpbRedirect {...props} />} />
      <Route path='/:appId/*' component={Home}/>
    </Switch>
  );
}

export default (AppRouter);