import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, ButtonBase,createMuiTheme } from "@material-ui/core";
import SubmitIcon from "@material-ui/icons/KeyboardArrowRight";

const theme = createMuiTheme();

const ButtonInput = ({
  label, onClick, key, buttonBaseClass, iconClass
}) => {
  return (
    <MuiThemeProvider key={key}  theme={theme} >
      <ButtonBase
        className={buttonBaseClass}
        onClick={onClick}>
        {label}<SubmitIcon className={iconClass} />
      </ButtonBase>
    </MuiThemeProvider>
  );
}

ButtonInput.propTypes = {
  label: PropTypes.string, 
  onClick: PropTypes.func, 
  key: PropTypes.string, 
  buttonBaseClass: PropTypes.string, 
  iconClass: PropTypes.string, 
}

export default ButtonInput;