import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
    type,max,
    label, name, value, placeholder, error, onChange, onBlur,
    wrapperClass, wrapperId, inputClass, labelClass, errorClass, 
    isRequired, onKeyPress, disabled, autofocus 
}) => {
    value = value ? value : '';
    autofocus = autofocus ? 'autofocus' : '';
    errorClass=errorClass?errorClass:"alert alert-danger";
    return (
        <div className={wrapperClass} id={wrapperId} >
            {
                label && (
                    <label className={labelClass} htmlFor={name}>{label}</label>
                )
            }
            <input 
                type={type} 
                className={inputClass} 
                name={name} 
                value={value} 
                id={name}
                maxLength={max}
                placeholder={placeholder} 
                onChange={onChange} 
                onBlur={onBlur} 
                onKeyPress={onKeyPress}
                onKeyDown={onKeyPress}
                aria-required="true" 
                autoComplete="off" 
                required={isRequired} 
                disabled={disabled} 
                autoFocus={autofocus}/>
            {
                error && (
                    <div className={errorClass}>{error}</div>
                ) 

            }
        </div>
    );
}

TextInput.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string, 
    name: PropTypes.string.isRequired, 
    value: PropTypes.any, 
    placeholder: PropTypes.string, 
    error: PropTypes.string, 
    onChange: PropTypes.func, 
    onBlur: PropTypes.func,
    wrapperClass: PropTypes.string, 
    wrapperId: PropTypes.string, 
    inputClass: PropTypes.string, 
    labelClass: PropTypes.string, 
    errorClass: PropTypes.string,
    isRequired: PropTypes.bool, 
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool, 
    autofocus: PropTypes.bool
}

export default TextInput;