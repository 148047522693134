
import { connect } from 'react-redux'; 
import ConfirmPassword from './ConfirmPassword';
import { actResetPasswordWithSecAnswer, actGetRecoveryQuestion } from '../../../store/actions/profileActions';

const mapStateToProps = (state) => {
    return {
        recovery: state.profile.recovery,
        application: state.admin.application,
        error: state.profile.error,
        status: state.profile.status,
        activateRedirect: state.profile.activateRedirect
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getRecoveryQuestion: (url, payload) => dispatch(actGetRecoveryQuestion(url, payload)),
        resetPasswordWithSecAnswer: (url, payload) => dispatch(actResetPasswordWithSecAnswer(url, payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)
