import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {  RegionDropdown, CountryRegionData } from 'react-country-region-selector';


const StateInput = ({ label, name, value, error, onChange,country,
    wrapperClass, wrapperId, inputClass, labelClass,
    errorClass, isRequired, disabled, autofocus }) => {
        const sref = useRef(null);
        const onStateSelect = (name, value) => {
            let e = {};
            e.target = { name, value };
            onChange(e);
        }
        useEffect(() => {
            if(sref.current != country){
                sref.current = country;
                onStateSelect('state','');
            }
        });
        errorClass=errorClass?errorClass:"alert alert-danger";
        return (
            <div className={wrapperClass} id={wrapperId} >
                    {
                        label && (
                            <label className={labelClass} htmlFor={name}>{'STATE/PROVINCE'}</label>
                        )
                    }
                    <RegionDropdown
                        name={name}
                        id={name}
                        className={inputClass}
                        aria-required="true"
                        autoComplete="off"
                        required={isRequired}
                        countryValueType="short"
                        disabled={disabled}
                        autoFocus={autofocus}
                        country={country}                        
                        value={value}
                        onChange={(value) => onStateSelect(name, value)} 
                        defaultOptionLabel="Select" />
                    {
                        error && (
                            <div className={errorClass}>{error}</div>
                        )
                    }
                </div>
        )
    
}

StateInput.propTypes = {
    label: PropTypes.string,
    country:PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    wrapperClass: PropTypes.string,
    wrapperId: PropTypes.string,
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    errorClass: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    autofocus: PropTypes.bool,
}

export default StateInput;
