import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message }) => {
    return(
        <div className="emr-form-error-message app-content-left error-message-section" id="errorMsg">
            <span  id="logonErrorMessage">
                {message.description}
            </span>
        </div>
    );
}

ErrorMessage.propTypes = {
    message: PropTypes.object.isRequired
}

export default ErrorMessage;