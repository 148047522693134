
import { connect } from 'react-redux'; 
import ForgotPassword from './ForgotPassword.js';
import { actSendPasswordResetEmail, actGetRecoveryQuestion } from '../../../store/actions/profileActions';

const mapStateToProps = (state) => {
    return {
        recovery: state.profile.recovery,
        application: state.admin.application,
        error: state.profile.error,
        status: state.profile.status,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        sendPasswordResetEmail: payload => dispatch(actSendPasswordResetEmail(payload)),
        //getRecoveryQuestion: (url,payload) => dispatch(actGetRecoveryQuestion(url,payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);