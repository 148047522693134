import { axiosSsoAdminInstance } from './constants';
import { prepareErrorResponse } from '../utils/helper';

export default class AdminApi {
    // static getAllApplications = () => {
    //     return axiosSsoAdminInstance.get('/admin/app')
    //         .then(response => response.data)
    //         .catch(error => {
    //             throw error
    //         });
    // }

    static getApplication = (appid) => {
        return axiosSsoAdminInstance.get(`/pub/app/${appid}`)
        .then(response => response.data)
        .catch(error => {
            throw error
        });
    }
    // static uploadAppLogo = (appId,application) => {        
    //     return axiosSsoAdminInstance.post(`/admin/app/upload/${appId}`,application)
    //     .then(response => response.data)
    //     .catch(error => {
    //         throw error
    //     });
    // }
    // static saveApplication = (application) => {
    //     let id = application.id;
    //     let appId = application.app_id;
    //     if(id) {
    //         return axiosSsoAdminInstance.put(`/admin/app/${appId}`, application)
    //             .then(() => application)
    //             .catch(error => {
    //                 throw error
    //             });
    //     }
    //     else {
    //         return axiosSsoAdminInstance.post(`/admin/app`, application)
    //         .then(response => {
    //             const id = response.data[0] || 0;
    //             if(id > 0) {
    //                 application = {...application, id};
    //                 return application
    //             } else {
    //                 throw new Error('Application not found')
    //             }
    //         })
    //         .catch(error => {
    //             throw error
    //         });
    //     }
        
    // }

    // static deleteApplication = (appid) =>{
    //     return axiosSsoAdminInstance.delete(`/app/${appid}`)
    //     .then()
    //     .catch(error => {
    //         throw error;
    //     })
    // }

    static generatePasswordResetEmail = (data) => {
        let app_id = data.app_id
        let baseUrl = data.baseUrl;
        let email = data.email;
        let state_token = data.state_token;
        return axiosSsoAdminInstance.get(`/pub/resetpasswords?baseUrl=${baseUrl}&toEmailID=${email}&app_id=${app_id}&state_token=${state_token}`, data)
        .then(response => response.data)
        .catch(error => {
            throw prepareErrorResponse(error);
        });
    }

    static generatePasswordResetEmail2 = (data) => {
        const app_id = data.app_id;
        const payload = { Login: data.email, baseUrl: data.baseUrl };

        return axiosSsoAdminInstance.post(`/pub/${app_id}/user/forgot_password`, payload)
            .then(response => response.data)
            .catch(error => {
                throw prepareErrorResponse(error);
            });
    }
}