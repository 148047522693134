import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';


const CountryInput = ({ label, name, value, error, onChange,
    wrapperClass, wrapperId, inputClass, labelClass,
    errorClass, isRequired, disabled, autofocus }) => {

    const onCountrySelect = (name, value) => {
        let e = {};
        e.target = { name, value };
        onChange(e);
    }
    errorClass=errorClass?errorClass:"alert alert-danger";
    return (
        <div className={wrapperClass} id={wrapperId} >
            {
                label && (
                    <label className={labelClass} htmlFor={name}>{'COUNTRY / REGION'}</label>
                )
            }
            <CountryDropdown
                name={name}
                id={name}
                valueType="short"
                className={inputClass}
                value={value}
                aria-required="true"
                autoComplete="off"
                required={isRequired}
                disabled={disabled}
                defaultOptionLabel="Select"
                autoFocus={autofocus}
                onChange={(value) => onCountrySelect(name, value)} />
                {
                    error && (
                        <div className={errorClass}>{error}</div>
                    )
                }
        </div> 
    );
}

CountryInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    wrapperClass: PropTypes.string,
    wrapperId: PropTypes.string,
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    errorClass: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    autofocus: PropTypes.bool,
}

export default CountryInput;
