import initialState from '../initialState';
import { SAVE_APPLICATION_SUCCESS, GET_ALL_APPLICATIONS_SUCCESS, GET_APPLICATION_SUCCESS, GET_ALL_APPLICATIONS_FAILED, SAVE_APPLICATION_FAILED, GET_APPLICATION_FAILED, CLEAR_APPLICATION_SUCCESS, GET_APPLICATION, GET_ALL_APPLICATIONS } from '../actionTypes';
import { STATUS_SAVED, STATUS_SUCCESS, STATUS_PROGRESS, STATUS_FAILED } from '../../utils/constants';

const adminReducers = (state = initialState.admin, action) => {
    switch(action.type) {

        // All Applications & Single Application GET
        case GET_ALL_APPLICATIONS:
        case GET_APPLICATION:
            return {
                ...state,
                status: STATUS_PROGRESS
            };

        case GET_ALL_APPLICATIONS_SUCCESS:
            return {
                ...state,
                applications: action.applications,
                status: STATUS_SUCCESS
            }
        case GET_ALL_APPLICATIONS_FAILED:
        case GET_APPLICATION_FAILED:
        case SAVE_APPLICATION_FAILED:
            return {
                ...state,
                error: action.error,
                status: STATUS_FAILED
            };

        // Single Application GET
        case GET_APPLICATION_SUCCESS: 
            return {
                ...state,
                application: action.application,
                status: STATUS_SUCCESS
            };

        // Single Application SAVE
        case SAVE_APPLICATION_SUCCESS: {
            let applications = [...state.applications]
                .filter(app => app.id !== action.application.id);
            applications = [...applications, action.application]
            return {
                ...state,
                applications,
                status: STATUS_SAVED
            };
        }    

        case CLEAR_APPLICATION_SUCCESS:
            return {
                ...state,
                application: {},
                status: ''
            }

        // None of the above
        default:
            return state;
    }
}

export default adminReducers;