import { connect } from "react-redux";
import Registration from './Registration';
import { actGetProfileSchema, actCreateProfile } from '../../store/actions/profileActions';

const mapStateToProps = state => {
    return {
        schema: state.profile.schema,
        error: state.profile.error,
        status: state.profile.status,
        mfaQuestions: state.profile.mfaQuestions,
        recoveryQuestions: state.profile.recoveryQuestions,
        application: state.admin.application
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileSchema: (url, payload) => dispatch(actGetProfileSchema(url, payload)),
        createProfile: (url, payload) => dispatch(actCreateProfile(url, payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);