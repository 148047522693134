import React, { Component } from 'react';
import PropTypes from "prop-types";
import validator from 'validator';
import Grid from '@material-ui/core/Grid';

import MessageComponent from './message-component';
import LoginFormComponent from './login-form';
import OktaRedirect from './redirect';
import { KEY_ENTER, KEY_TAB } from '../../utils/constants';
import { checkIsInternalUser, getOktaUrls, getBaseUrl, generateRandomNumber } from '../../utils/helper';
import { REACT_SSO_AUTHORIZATION_PATH, INTERNAL_OKTA_REDIRECT_URL, EXTERNAL_OKTA_BASE_URL, EXTERNAL_OKTA_REDIRECT_URL } from '../../api/constants';

class SSOLogin extends Component {
    state = {
        error: {},
        credential: {
            username: '',
            sec: ''
        },
        message: {
            type: 'info',
            header: 'Login Failed',
            description: 'Enter correct credentials'
        },
        redirectUrl: null,
        appId: null
    }

    componentDidMount = () => {
        const {  match } = this.props;
        const { appId } = match.params;
        this.setState({appId});
    }

    componentDidUpdate = (prevProps) => {
        const { profile } = this.props;
        

        if(prevProps.profile !== profile) {
            // Got user profile, can redirect to landing page
            this.updateRedirectUrl();
        }
    }

    render() {
        const { authError } = this.props;
        const { credential, error, redirectUrl } = this.state;
        const apiError = Object.keys(authError).length > 0 ? authError : null;
        
        return ( 
            <div className="app-container"> 
                {  
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                }
                <Grid container spacing={0}>
                    <Grid item md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <div className="app-content" >
                                <h2 className="app-content-h2"> Tools for Your Application Needs  </h2> 
                                <h3 className="app-content-h3"> Sign in or register once to access
                                all of your Emerson accounts. 
                                </h3>
                                <h5 className="app-content-h5">
                                    Get access to Emerson’s Tools using just one username and one password with our new seamless platform. While we build, please note that you may still be required to use existing usernames and passwords
                                    for some web applications.
                                </h5>
                                {
                                    apiError && (
                                        <MessageComponent message = {authError}/>
                                    )
                                }
                                
                            </div> 
            
                            <LoginFormComponent 
                                onSignIn = {this.submitForm}
                                onChange = {this.handleChange}
                                data={credential} 
                                handleKeyPress = {this.handleKeyPress} 
                                handleRouteToFrgtPswd = {this.handleRouteToFrgtPswd}
                                handleRouteToRegistration = {this.handleRouteToRegistration}
                                error={error}
                                apiError={apiError} /> 
                        </Grid >
                    <Grid item md={2}> </Grid> 
                </Grid >
            </div>
        );
    }

     //New SSO      
    updateRedirectUrl = () => {
        const { profile, application } = this.props;
        const { credential } = this.state;
        let app_redirect_url = application.app_redirect_url;
        const isInternalUser = checkIsInternalUser(credential.username);
        let nonce = `&nonce=${generateRandomNumber(7)}`;

        if(!isInternalUser){
             //https://accessemr.oktapreview.com/home/oidc_client/0oakvziwcaNzBZTLp0h7/aln5z7uhkbM6y7bMy0g7?sessionToken=02jsousf70wjasl
            // let redirectUrl = `${EXTERNAL_OKTA_BASE_URL}${app_redirect_url}?sessionToken=${profile.sessiontoken}`;
            //     redirectUrl = `${redirectUrl}${nonce}`;

            let sso_redirect_back = `${window.location.origin}/sso/session/ext?AppUrl=${app_redirect_url}`;
           
            let redirectUrl = `${EXTERNAL_OKTA_REDIRECT_URL}?`;
                  redirectUrl = `${redirectUrl}token=${profile.sessiontoken}`;
                  redirectUrl = `${redirectUrl}&redirectUrl=${sso_redirect_back}`;
                  redirectUrl = `${redirectUrl}${nonce}`;


                redirectUrl = encodeURI(redirectUrl);
            this.setState({redirectUrl})
        }

        if(isInternalUser){
            let sso_redirect_back = `${window.location.origin}/sso/session/int?AppUrl=${app_redirect_url}`;

            let redirectUrl_in = `${INTERNAL_OKTA_REDIRECT_URL}?`;
                redirectUrl_in = `${redirectUrl_in}token=${profile.sessiontoken}`;
                redirectUrl_in = `${redirectUrl_in}&redirectUrl=${sso_redirect_back}`;
                redirectUrl_in = `${redirectUrl_in}${nonce}`;
            
                redirectUrl_in = encodeURI(redirectUrl_in);
            this.setState({redirectUrl:redirectUrl_in})  
        } 
    }
    handleChange = event => {
        let error = Object.assign({}, this.state.error);
        const { message, clearAuthenticateError } = this.props;

        const name = event.target.name;
        const value = event.target.value;
        let credential = Object.assign({}, this.state.credential);
        credential[name] = value;
        this.setState({ credential }, () => {
            if (message) {
                clearAuthenticateError();
            }
            if(error[name]) {
                let newError = this.getFormErrors(name, credential);
                this.setState({error: newError})
            }
        });
    };

    handleRouteToFrgtPswd = () => {
        const { history, application, clearAuthenticateError } = this.props;
        clearAuthenticateError();
        history.push(`/${application.app_id}/forgot-password`)
    }

    handleRouteToRegistration = () => {
        const { history, application, clearAuthenticateError } = this.props;
        clearAuthenticateError();
        history.push(`/${application.app_id}/registration/ssologin`)
        //window.location.replace('https://www.emerson.com/catalog/UserRegistrationForm?myAcctMain=1&new=Y&catalogId=20051&langId=-1&storeId=20151');
    }

    getFormErrors = (name, credential) => {
        let error = Object.assign({}, this.state.error);
        let fields = Object.keys(credential).filter(f => name === null || f === name);
        
        fields.map(name => {
            let hasValid = false;
            let errorMsg = '';
            if(name === "username") {
                hasValid = validator.isEmail(credential[name] || '');
                errorMsg = "Please enter a valid email format";
            } else if(name === "sec") {
                hasValid = !validator.isEmpty(credential[name] || '');
                errorMsg = "Password field cannot be empty";
            }

            if(hasValid) {
                delete error[name];
            } else {
                error[name] = errorMsg;
            }

            return name;
        });
        
        return error;
    }

    submitForm = e => {
        e.preventDefault();

        let credential = Object.assign({}, this.state.credential);
        let error = this.getFormErrors(null, credential);

        this.setState({error}, () => {
            if(Object.keys(error).length === 0) {
                const { authenticateUser, application } = this.props;
                const isInternalUser = checkIsInternalUser(credential.username);
                let oktaUrls = getOktaUrls(credential.username, application);
                let url = '';
                const data = {
                    Login: credential.username,
                    Password: credential.sec
                }

                authenticateUser(application.app_id, data);
            }
        });
    }

    handleKeyPress = (event) => {
        if (event.key === KEY_ENTER) {
            this.submitForm(event);
        } else if(event.key === KEY_TAB) {
            const name = event.target.name;
            let error = this.getFormErrors(name, this.state.credential);
            this.setState({error})
        }
    }
}

SSOLogin.propTypes = {
    message: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    authenticateUser: PropTypes.func.isRequired,
    clearAuthenticateError: PropTypes.func.isRequired,
    authError: PropTypes.object,
    application: PropTypes.object.isRequired,
    history: PropTypes.object,
};

export default (SSOLogin);