import { connect } from 'react-redux';
import ProfileManagement from './ProfileManagement';
import { 
    actGetProfile, actSaveProfile, actGetEditProfileRecoveryQuestion, 
    actVerifyEditProfileRecoveryAnswer
} from '../../store/actions/profileActions';
import { actSetIdTokenToLocalStorage, actGetIdTokenFromLocalStorage, actClearIdTokenFromLocalStorage } from '../../store/actions/storageActions';

const mapStateToProps = (state) => {
    return {
        schema: state.profile.schema,
        profile: state.profile.data,
        status: state.profile.status,
        error: state.profile.error,
        authError: state.auth.error,
        recovery: state.profile.recovery,
        recoveryQuestions: state.profile.recoveryQuestions,
        application: state.admin.application,
        storageStatus: state.storage.status,
        idToken: state.storage.idToken,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // will return the profile & schema
        getProfile: (url, payload) => dispatch(actGetProfile(url, payload)),
        saveProfile: (url, payload) => dispatch(actSaveProfile(url, payload)),
        
        // will return the security question
        getProfileRecoveryQuestion: (url, payload) => dispatch(actGetEditProfileRecoveryQuestion(url, payload)),
        verifyProfileRecoveryAnswer: (url, payload) => dispatch(actVerifyEditProfileRecoveryAnswer(url, payload)),

        // will return storage values
        setIdTokenToLocalStorage: payload => dispatch(actSetIdTokenToLocalStorage(payload)),
        getIdTokenFromLocalStorage: payload => dispatch(actGetIdTokenFromLocalStorage(payload)),
        clearIdTokenFromLocalStorage: payload => dispatch(actClearIdTokenFromLocalStorage(payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileManagement);