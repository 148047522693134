import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from "prop-types";
import {SSO_ADMIN_PUB_URL} from '../../api/constants';
import {STATUS_ACTIVATED, STATUS_FAILED, USER_ALREADY_EXISTING_MESSAGE} from '../../utils/constants';
import notify from '../../utils/notify';

class ProfileActivate extends Component {
    state = {
        error: {},
        redirectUrl: null,
        appId: null,
        token: null
    }

    componentDidMount = () => {
        const { match, actAccount } = this.props;
        const { appId, token } = match.params;
        const url = `${SSO_ADMIN_PUB_URL}/${appId}/`;
        actAccount(url, {token, appId});
    }

    componentDidUpdate = (prevProps) => {
        const { status, error, activateRedirect, history, application } = this.props;
        if(prevProps.status !== status) {
            if(status === STATUS_ACTIVATED) {
                notify({message: 'User activated successfully.'}, 'success');     
                if(prevProps.activateRedirect !== activateRedirect){
                   history.push(`/${application.app_id}/${activateRedirect}`);
                }  
            }
            if(status === STATUS_FAILED) {
                if(error.description.includes("field already exists")){
                error['email']= USER_ALREADY_EXISTING_MESSAGE;
            
                this.setState({error})
                }
                else
                notify({message: error.description}, 'error');
                history.push(`/${application.app_id}/${activateRedirect}`);
            }
        }

       
    }

    render() {
        const { authError, activateRedirect } = this.props;
        const { redirectUrl } = '';
        return ( 
           
            <div className="app-container"> 
                { <div> 
                    
                 </div> 
                } 
            </div>
        );
    }

  
}

ProfileActivate.propTypes = {
    actAccount: PropTypes.func.isRequired,
  };

export default ProfileActivate;