import initialState from "../initialState";
import { 
    GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED, 
    GET_PROFILE_SCHEMA, GET_PROFILE_SCHEMA_SUCCESS, GET_PROFILE_SCHEMA_FAILED, 
    SAVE_PROFILE, SAVE_PROFILE_SUCCESS, SAVE_PROFILE_FAILED, 
    GET_USER_MFA_QUESTIONS, GET_USER_MFA_QUESTIONS_SUCCESS, GET_USER_MFA_QUESTIONS_FAILED, 
    GET_FGT_SECWORD_RECOVERY_QUESTION, GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS, GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED, 
    RESET_SECWORD, RESET_SECWORD_SUCCESS, RESET_SECWORD_FAILED, 
    CHANGE_SECWORD, CHANGE_SECWORD_SUCCESS, CHANGE_SECWORD_FAILED, 
    CHANGE_PSWD_SEC_QUES_ANS, CHANGE_PSWD_SEC_QUES_ANS_SUCCESS, CHANGE_PSWD_SEC_QUES_ANS_FAILED, 
    GET_EDIT_PROFILE_RECOVERY_QUESTION, GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS, GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED, 
    VERIFY_EDIT_PROFILE_RECOVERY_ANSWER, VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS, VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED, CREATE_PROFILE, CREATE_PROFILE_SUCCESS, CREATE_PROFILE_FAILED, SEND_SECWORD_RESET_EMAIL_SUCCESS, SEND_SECWORD_RESET_EMAIL_FAILED, SEND_SECWORD_RESET_EMAIL, GET_USER_SESSION, GET_USER_SESSION_SUCCESS, GET_USER_SESSION_FAILED, ACTIVATE_PROFILE, ACTIVATE_PROFILE_SUCCESS, ACTIVATE_PROFILE_FAILED 
} from "../actionTypes";
import { STATUS_PROGRESS, STATUS_SUCCESS, STATUS_FAILED, STATUS_SAVED, STATUS_VERIFIED, STATUS_CREATED, STATUS_ACTIVATED } from "../../utils/constants";

const profileReducers = (state = initialState.profile, action) => {
    switch(action.type) {
        // Profile Schema
        case GET_PROFILE_SCHEMA: 
        case GET_PROFILE:
        case CREATE_PROFILE:
        case ACTIVATE_PROFILE:
        case SAVE_PROFILE:
        case GET_FGT_SECWORD_RECOVERY_QUESTION:
        case RESET_SECWORD:
        case CHANGE_SECWORD:
        case CHANGE_PSWD_SEC_QUES_ANS:
        case GET_EDIT_PROFILE_RECOVERY_QUESTION:
        case VERIFY_EDIT_PROFILE_RECOVERY_ANSWER:
        case GET_USER_MFA_QUESTIONS:
        case SEND_SECWORD_RESET_EMAIL:
        case GET_USER_SESSION:
            return {
                ...state,
                status: STATUS_PROGRESS
            };
        case GET_PROFILE_SCHEMA_SUCCESS: 
            return {
                ...state,
                schema: action.schema,
                status: STATUS_SUCCESS
            };
        case GET_PROFILE_SCHEMA_FAILED: 
            return {
                ...state,
                status: STATUS_FAILED
            };

        // Profile
        case GET_PROFILE_SUCCESS: 
            return {
                ...state,
                data: action.data,
                status: STATUS_SUCCESS
            };
        case GET_PROFILE_FAILED: 
        case CREATE_PROFILE_FAILED:
        case SAVE_PROFILE_FAILED:
        case GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED:
        case CHANGE_SECWORD_FAILED:
        case CHANGE_PSWD_SEC_QUES_ANS_FAILED:
        case GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED:
        case VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED:
        case GET_USER_MFA_QUESTIONS_FAILED:
        case SEND_SECWORD_RESET_EMAIL_FAILED:
        case GET_USER_SESSION_FAILED:
            return {
                ...state,
                error: action.error,
                status: STATUS_FAILED
            };

        // Create Profile
        case CREATE_PROFILE_SUCCESS: 
            return {
                ...state,
                status: STATUS_CREATED
            };
        
        
        // Create Profile
        case ACTIVATE_PROFILE_SUCCESS: 
            return {
                ...state,
                status: STATUS_ACTIVATED,
                activateRedirect: action.data.activateRedirect
            };
        
        // Activate profile error        
        case ACTIVATE_PROFILE_FAILED:
        case RESET_SECWORD_FAILED:    
            return {
                ...state,
                error: action.error,
                status: STATUS_FAILED,
                activateRedirect: action.error.activateRedirect
            };

        // Save Profile
        case SAVE_PROFILE_SUCCESS: 
            return {
                ...state,
                data: action.data,
                status: STATUS_SAVED
            };

        // Get recovery question for a particular user
        case GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS:
            return {
                ...state,
                recovery:action.recovery,
                // stateToken: action.recovery.StateToken,
                status: STATUS_SUCCESS
            };

        // Reset after verifying the security question answer for a particular user
        case RESET_SECWORD_SUCCESS:
            return {
                ...state,
                status: STATUS_VERIFIED,
                activateRedirect: action.message.activateRedirect
            };

        // Reset for a particular user
        // Send the reset email
        case CHANGE_SECWORD_SUCCESS:
        case SEND_SECWORD_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                status: STATUS_SUCCESS
            };

        // ???
        case CHANGE_PSWD_SEC_QUES_ANS_SUCCESS:
            return {
                ...state,
                message: action.message.Summary,
                status: STATUS_SUCCESS
            };

        // Get Recovery question before editing the profile
        case GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS:
            return {
                ...state,
                recovery: action.response,
                status: STATUS_SUCCESS
            };

        // Verify recovery question answer before editing the profile
        case VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS: 
            return {
                ...state,
                recovery: action.response,
                status: STATUS_VERIFIED
            };

        // Get Multi-Factor Authentication Questions
        case GET_USER_MFA_QUESTIONS_SUCCESS: 
            return {
                ...state,
                mfaQuestions: action.mfaQuestions,
                status: STATUS_SUCCESS
            };

        case GET_USER_SESSION_SUCCESS:
            return {
                ...state,
                status: STATUS_SUCCESS,
                session: action.session
            };

        default:
            return state;
    }
}

export default profileReducers;