import React, { Component } from 'react';
import PropTypes from "prop-types";
import { getOktaUrls, parseJwt } from '../../utils/helper';
import { STATUS_SUCCESS, STATUS_FAILED } from '../../utils/constants';
import OktaRedirect from '../login/redirect';
import { SSO_ADMIN_PUB_URL } from '../../api/constants';

class Logout extends Component {
    state = {
        referrer: '',
        redirectUrl: null
    }

    componentDidMount = () => {
        let referrer = document.referrer;
        this.setState({ referrer });
        const { logoutUser, application, match, clearIdTokenFromLocalStorage } = this.props;
        const { idtoken } = match.params;

        let parsedIdToken = parseJwt(idtoken);
        const login = parsedIdToken.email || '';

        let oktaUrls = getOktaUrls(login, application);
        let url = oktaUrls.oktaUrl;
        //const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
        
        // Clear local storage
        clearIdTokenFromLocalStorage();
        
        // init logout
        logoutUser(url);
    }

    componentDidUpdate = (prevProps) => {
        const { status } = this.props;

        if(prevProps.status !== status) {
            if(status === STATUS_SUCCESS) {
                this.redirectToOrigin();
            }

            if(status === STATUS_FAILED) {
                this.redirectToOrigin();
            }
        }
    }

    render() {
        const { redirectUrl } = this.state;
        return (
            <div>
                {
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                }
            </div>
        )
    }

    redirectToOrigin = () => {
        const { location, history, match } = this.props;
        const { referrer } = this.state;
        const { appId } = match.params;

        if(referrer === '' || referrer.includes(location.pathname)) {
            history.push(`/${appId}/login`)
        } else {
            this.setState({
                redirectUrl: referrer
            })
        }
    }
}

Logout.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    clearIdTokenFromLocalStorage: PropTypes.func.isRequired,
    application: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    status: PropTypes.object,
}

export default Logout;