import profileApi from '../../api/profileApi';

import { 
    GET_PROFILE_SCHEMA, GET_PROFILE_SCHEMA_SUCCESS, GET_PROFILE_SCHEMA_FAILED, 
    GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED, 
    SAVE_PROFILE, SAVE_PROFILE_SUCCESS, SAVE_PROFILE_FAILED, 
    GET_USER_MFA_QUESTIONS, GET_USER_MFA_QUESTIONS_SUCCESS, GET_USER_MFA_QUESTIONS_FAILED, 
    GET_FGT_SECWORD_RECOVERY_QUESTION, GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS, GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED, 
    RESET_SECWORD, RESET_SECWORD_SUCCESS, RESET_SECWORD_FAILED, 
    CHANGE_SECWORD, CHANGE_SECWORD_SUCCESS, CHANGE_SECWORD_FAILED, 
    CHANGE_PSWD_SEC_QUES_ANS, CHANGE_PSWD_SEC_QUES_ANS_SUCCESS, CHANGE_PSWD_SEC_QUES_ANS_FAILED, 
    GET_EDIT_PROFILE_RECOVERY_QUESTION, GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS, GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED, 
    VERIFY_EDIT_PROFILE_RECOVERY_ANSWER, VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS, VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED, 
    CREATE_PROFILE, CREATE_PROFILE_SUCCESS, CREATE_PROFILE_FAILED, 
    SEND_SECWORD_RESET_EMAIL, SEND_SECWORD_RESET_EMAIL_SUCCESS, SEND_SECWORD_RESET_EMAIL_FAILED, 
    GET_USER_SESSION, GET_USER_SESSION_SUCCESS, GET_USER_SESSION_FAILED, ACTIVATE_PROFILE, ACTIVATE_PROFILE_SUCCESS, ACTIVATE_PROFILE_FAILED,
} from '../actionTypes';
import adminApi from '../../api/adminApi';

export const actGetProfile = (url, {appSpecificSchema, id, includeCredentialSchema, editMode, isRegister, idToken}) => {
    return dispatch => {
        dispatch(actGetProfileInit())
        return profileApi.getProfile(url, id, idToken)
            .then(data => {
                dispatch(actGetProfileSuccess(data));
                dispatch(actGetProfileSchema(url, {appSpecificSchema, includeCredentialSchema, editMode, isRegister}))
            })
            .catch(error => dispatch(actGetProfileFailed(error)))
    }
}

const actGetProfileInit = () => {
    return {
        type: GET_PROFILE
    }
}

const actGetProfileSuccess = data => {
    return {
        type: GET_PROFILE_SUCCESS,
        data
    }
}

const actGetProfileFailed = error => {
    return {
        type: GET_PROFILE_FAILED,
        error
    }
}

export const actGetProfileSchema = (url, {appSpecificSchema,includeCredentialSchema, editMode, isRegister}) => {
    return dispatch => {
        dispatch(actGetProfileSchemaInit())
        return profileApi.getProfileSchema(url,appSpecificSchema, includeCredentialSchema, editMode, isRegister)
            .then(schema => dispatch(actGetProfileSchemaSuccess(schema)))
            .catch(error => dispatch(actGetProfileSchemaFailed(error)));
    };
}

const actGetProfileSchemaInit = () => {
    return {
        type: GET_PROFILE_SCHEMA
    }
}
const actGetProfileSchemaSuccess = (schema) => {
    return {
        type: GET_PROFILE_SCHEMA_SUCCESS,
        schema
    }
}

const actGetProfileSchemaFailed = (error) => {
    return {
        type: GET_PROFILE_SCHEMA_FAILED,
        error
    }
}

// Create New User
export const actCreateProfile = (url, data) => {
    return dispatch => {
        dispatch(actCreateProfileInit())
        return profileApi.createUser(url, data)
            .then(response => {
                dispatch(actCreateProfileSuccess(response));
                //dispatch(actActivateProfile(url, {login: data.login}))
            })
            .catch(error => dispatch(actCreateProfileFailed(error)))
    }
}

const actCreateProfileInit = () => {
    return {
        type: CREATE_PROFILE
    }
}

const actCreateProfileSuccess = profile => {
    return {
        type: CREATE_PROFILE_SUCCESS,
        profile
    }
}

const actCreateProfileFailed = error => {
    return {
        type: CREATE_PROFILE_FAILED,
        error
    }
}

// Activate New User
export const actActivateProfile = (url, data) => {
    return dispatch => {
        dispatch(actActivateProfileInit())
        return profileApi.activateUser(url, data)
            .then(response => {
                dispatch(actActivateProfileSuccess(response));
            })
            .catch(error => dispatch(actActivateProfileFailed(error)))
    }
}

const actActivateProfileInit = () => {
    return {
        type: ACTIVATE_PROFILE
    }
}

const actActivateProfileSuccess = data => {
    return {
        type: ACTIVATE_PROFILE_SUCCESS,
        data
    }
}

const actActivateProfileFailed = error => {
    return {
        type: ACTIVATE_PROFILE_FAILED,
        error
    }
}

// Update Existing User
export const actSaveProfile = (url, data) => {
    return dispatch => {
        dispatch(actSaveProfileInit())
        return profileApi.saveUser(url, data)
            .then(() => dispatch(actSaveProfileSuccess(data)))
            .catch(error => dispatch(actSaveProfileFailed(error)))
    }
}

const actSaveProfileInit = () => {
    return {
        type: SAVE_PROFILE
    }
}

const actSaveProfileSuccess = data => {
    return {
        type: SAVE_PROFILE_SUCCESS,
        data
    }
}

const actSaveProfileFailed = error => {
    return {
        type: SAVE_PROFILE_FAILED,
        error
    }
}

export const actGetRecoveryQuestion = (url,data) => {
    return dispatch => {
        dispatch(actGetRecoveryQuestionInit());
        // return profileApi.getForgotPasswordRecToken(url,data)
        //     .then(response => {
        //         dispatch(actVerifyRecoveryToken(url,response))
        //     })
        //     .catch(error => dispatch(actGetRecoveryQuestionFailed(error)));
        return profileApi.getForgotPasswordSecQuestion(url, data)
            .then(response => dispatch(actGetRecoveryQuestionSuccess(response)))
            .catch(error => dispatch(actGetRecoveryQuestionFailed(error)));
    }
}

const actGetRecoveryQuestionInit = () => {
    return {
        type: GET_FGT_SECWORD_RECOVERY_QUESTION
    };
}

export const actVerifyRecoveryToken = (url,data) => {
    return dispatch => {
        return profileApi.getForgotPasswordSecQuestion(url, data)
            .then(response => dispatch(actGetRecoveryQuestionSuccess(response)))
            .catch(error => dispatch(actGetRecoveryQuestionFailed(error)));
    }
}

const actGetRecoveryQuestionSuccess = recovery => {
    return {
        type: GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS,
        recovery
    }
}

const actGetRecoveryQuestionFailed = error => {
    return {
        type: GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED,
        error
    };
}

export const actResetPasswordWithSecAnswer = (url,data) => {

    return dispatch => {
        dispatch(actResetPasswordWithSecAnswerInit());
        return profileApi.resetPasswordWithSecAnswer(url, data)
            .then(response => dispatch(actResetPasswordWithSecAnswerSuccess(response)))
            .catch(error => dispatch(actResetPasswordWithSecAnswerFailed(error)));
    }
}

const actResetPasswordWithSecAnswerInit = () => {
    return {
        type: RESET_SECWORD
    }
}

const actResetPasswordWithSecAnswerSuccess = (message) => {

    return {
        type: RESET_SECWORD_SUCCESS,
        message
    }
}

const actResetPasswordWithSecAnswerFailed = (error) => {
    return {
        type: RESET_SECWORD_FAILED,
        error
    }
}

export const actChangePassword = (url, data) => {
    return dispatch => {
        dispatch(actChangePasswordInit());
        return profileApi.changePassword(url, data)
            .then(response => dispatch(actChangePasswordSuccess(response)))
            .catch(error => dispatch(actChangePasswordFailed(error)));
    }
}

const actChangePasswordInit = () => {
    return {
        type: CHANGE_SECWORD
    }
}

const actChangePasswordSuccess = (message) => {
    return {
        type: CHANGE_SECWORD_SUCCESS,
        message
    }
}

const actChangePasswordFailed = (error) => {
    return {
        type: CHANGE_SECWORD_FAILED,
        error
    }
}

export const actChangePswdSecQuestionAnswer = (url,data) => {
    return dispatch => {
        dispatch(actChangePswdSecQuestionAnswerInit());
        return profileApi.changePasswordSecQuestionAnswer(url, data)
            .then(response => dispatch(actChangePswdSecQuestionAnswerSuccess(response)))
            .catch(error => dispatch(actChangePswdSecQuestionAnswerFailed(error)));
    }
}

const actChangePswdSecQuestionAnswerInit = () => {
    return {
        type: CHANGE_PSWD_SEC_QUES_ANS
    }
}

const actChangePswdSecQuestionAnswerSuccess = (message) => {
    return {
        type: CHANGE_PSWD_SEC_QUES_ANS_SUCCESS,
        message
    }
}

const actChangePswdSecQuestionAnswerFailed = (error) => {
    return {
        type: CHANGE_PSWD_SEC_QUES_ANS_FAILED,
        error
    }
}

export const actGetEditProfileRecoveryQuestion = (url, data) => {
    return dispatch => {
        dispatch(actGetEditProfileRecoveryQuestionInit());
        return profileApi.getEditProfileSecQuestion(url, data)
            .then(response => { 
                dispatch(actGetEditProfileRecoveryQuestionSuccess(response))
            })
            .catch(error => dispatch(actGetEditProfileRecoveryQuestionFailed(error)));
    }
}

const actGetEditProfileRecoveryQuestionInit = () => {
    return {
        type: GET_EDIT_PROFILE_RECOVERY_QUESTION
    };
}

const actGetEditProfileRecoveryQuestionSuccess = (response) => {

    return {
        type: GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS,
        response
    }
}

const actGetEditProfileRecoveryQuestionFailed = (error) => {
    return {
        type: GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED,
        error
    }
}

export const actVerifyEditProfileRecoveryAnswer = (url, data) => {
    return dispatch => {
        dispatch(actVerifyEditProfileRecoveryAnswerInit());
        return profileApi.verifyEditProfileSecAnswer(url, data)
            .then(response => {
                dispatch(actVerifyEditProfileRecoveryAnswerSuccess(response))
            })
            .catch(error => dispatch(actVerifyEditProfileRecoveryAnswerFailed(error)));
    }
}

const actVerifyEditProfileRecoveryAnswerInit = () => {
    return {
        type: VERIFY_EDIT_PROFILE_RECOVERY_ANSWER
    };
}

const actVerifyEditProfileRecoveryAnswerSuccess = (response) => {
    return {
        type: VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS,
        response
    }
}

const actVerifyEditProfileRecoveryAnswerFailed = (error) => {
    return {
        type: VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED,
        error
    }
}

export const actGetUserMfaQuestions = (url,username) => {
    return dispatch => {
        dispatch(actGetUserMfaQuestionsInit())
        return profileApi.getUserMfaQuestions(url,username)
            .then(response => dispatch(actGetUserMfaQuestionsSuccess(response)))
            .catch(error => dispatch(actGetUserMfaQuestionsFailed(error)));
    };
}

const actGetUserMfaQuestionsInit = () => {
    return {
        type: GET_USER_MFA_QUESTIONS
    };
}

const actGetUserMfaQuestionsSuccess = (mfaQuestions) => {
    return {
        type: GET_USER_MFA_QUESTIONS_SUCCESS,
        mfaQuestions
    };
}

const actGetUserMfaQuestionsFailed = (error) => {
    return {
        type: GET_USER_MFA_QUESTIONS_FAILED,
        error
    };
}

export const actSendPasswordResetEmail = data => {
    return dispatch => {
        dispatch(actSendPasswordResetEmailInit())
        return adminApi.generatePasswordResetEmail2(data)
            .then(() =>dispatch(actSendPasswordResetEmailSuccess()))
            .catch(error => dispatch(actSendPasswordResetEmailFailed(error)))
    }
}

const actSendPasswordResetEmailInit = () => {
    return {
        type: SEND_SECWORD_RESET_EMAIL
    }
}

const actSendPasswordResetEmailSuccess = () => {
    return {
        type: SEND_SECWORD_RESET_EMAIL_SUCCESS
    }
}

const actSendPasswordResetEmailFailed = (error) => {
    return {
        type: SEND_SECWORD_RESET_EMAIL_FAILED,
        error
    }
}

export const actGetUserSession = ({internal_okta_url, external_okta_url}) => {
    return dispatch => {
        dispatch(actGetUserSessionInit())
        return profileApi.getUserSession(internal_okta_url)
            .then(response => dispatch(actGetUserSessionSuccess(response)))
            .catch(() => {
                return profileApi.getUserSession(external_okta_url)
                    .then(response => dispatch(actGetUserSessionSuccess(response)))
                    .catch(error => dispatch(actGetUserSessionFailed(error)))
            })
    }
}

//New SSO Session Check
export const actGetSSOUserSession = ({external_okta_url}) => {
    return dispatch => {
                    dispatch(actGetUserSessionInit())
                    return profileApi.getUserSession(external_okta_url)
                        .then(response => dispatch(actGetUserSessionSuccess(response)))
                        .catch(error => dispatch(actGetUserSessionFailed(error)))
                     }
    
}


const actGetUserSessionInit = () => {
    return {
        type: GET_USER_SESSION
    }
}

const actGetUserSessionSuccess = (session) => {
    return {
        type: GET_USER_SESSION_SUCCESS,
        session
    }
}

const actGetUserSessionFailed = (error) => {
    return {
        type: GET_USER_SESSION_FAILED,
        error
    }
}