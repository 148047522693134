import React from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const PhoneInput = ({
  label, name, value, error, onChange,
  wrapperClass, wrapperId, inputClass, labelClass, errorClass, 
  isRequired, disabled, autofocus
}) => {
  autofocus = autofocus ? 'autofocus' : '';
  errorClass=errorClass?errorClass:"alert alert-danger";
  
    const handleChange = val => {
        let event = {};
        event.target = {};
        event.target.value = val;
        event.target.name = name;
        onChange(event);
    }

  return (
    <div className={wrapperClass} id={wrapperId} >
            {
                label && (
                    <label className={labelClass} htmlFor={name}>{label}</label>
                )
            }
      
        <ReactPhoneInput 
            className={inputClass}
            labelClass="app-content-label"
            label="phone"
            name={name}
            id={name}
            defaultCountry={'us'} 
            value={value? value :''} 
            onChange = { handleChange } 
            aria-required="true" 
            autoComplete="off" 
            required={isRequired} 
            disabled={disabled} 
            autoFocus={autofocus}/>
        {
            error && (
                <div className={errorClass}>{error}</div>
            )
        }
    </div>
  )
}

PhoneInput.propTypes = {
  label: PropTypes.string, 
  name: PropTypes.string,  
  value: PropTypes.string,  
  error: PropTypes.string,  
  onChange: PropTypes.func, 
  wrapperClass: PropTypes.string,  
  wrapperId: PropTypes.string,  
  inputClass: PropTypes.string,  
  labelClass: PropTypes.string,  
  errorClass: PropTypes.string,  
  isRequired: PropTypes.bool,  
  disabled: PropTypes.bool,  
  autofocus: PropTypes.bool, 
}

export default PhoneInput;
