import React, { useState }  from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
// import '../../App.css';
const eye = <FontAwesomeIcon icon={faEye} />;

const PasswordInput = ({
    type,
    label, name, value, placeholder, error, onChange, onBlur,
    wrapperClass, wrapperId, inputClass, labelClass, errorClass, 
    isRequired, onKeyPress, disabled, autofocus
}) => {
    autofocus = autofocus ? 'autofocus' : '';
    errorClass=errorClass?errorClass: 'alert alert-danger';


    const validatePasswordText = e => {
        // Preventing spaces
        e.target.value = e.target.value.trim();
        onChange(e);
    }

    const preventPaste = e => {
        e.preventDefault();
    }

    const preventCopy = e => {
        e.preventDefault();
    }

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

    return (
        <div className={wrapperClass} id={wrapperId} >
            {
                label && (
                    <label className={labelClass} htmlFor={name}>{label}</label>
                )
            }
            <div className="pass-wrapper">
            <input className={inputClass} name={name} value={value} id={name}
                placeholder={placeholder} onChange={validatePasswordText} onBlur={onBlur} 
                onKeyPress={onKeyPress} onKeyDown={onKeyPress}
                aria-required="true" autoComplete="off" required={isRequired} 
                disabled={disabled} autoFocus={autofocus} type={passwordShown ? "text" : "password"} />
                {/* onPaste={preventPaste} onCopy={preventCopy}/> */}
                <i onClick={togglePasswordVisiblity}>{eye}</i>
            </div>
            {
                error && (
                    <div className={errorClass}>{error}</div>
                ) 

            }
        </div>
    );
}

PasswordInput.propTypes = {
    type: PropTypes.string.isRequired,
    label: PropTypes.string, 
    name: PropTypes.string, 
    value: PropTypes.any, 
    placeholder: PropTypes.string, 
    error: PropTypes.string, 
    onChange: PropTypes.func, 
    onBlur: PropTypes.func,
    wrapperClass: PropTypes.string, 
    wrapperId: PropTypes.string,
    inputClass: PropTypes.string, 
    labelClass: PropTypes.string, 
    errorClass: PropTypes.string,
    isRequired: PropTypes.bool, 
    onKeyPress: PropTypes.func, 
    disabled: PropTypes.bool,
    autofocus: PropTypes.bool, 
}

export default PasswordInput;