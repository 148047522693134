import { combineReducers } from "redux";
import auth from './authenticateReducers';
import admin from './adminReducers';
import profile from './profileReducers';
import storage from './storageReducers';

export default combineReducers({
    auth,
    admin,
    profile,
    storage,
})