import { 
    GET_ALL_APPLICATIONS_SUCCESS, GET_ALL_APPLICATIONS_FAILED,
    GET_APPLICATION_SUCCESS, GET_APPLICATION_FAILED,
    SAVE_APPLICATION_SUCCESS, SAVE_APPLICATION_FAILED, CLEAR_APPLICATION_SUCCESS, 
    GET_APPLICATION,UPLOAD_APP_LOGO_SUCCESS,UPLOAD_APP_LOGO_FAILED
} from "../actionTypes";
import adminApi from '../../api/adminApi';

export const actGetAllApplications = () => {
    return dispatch => {
        return adminApi.getAllApplications()
            .then(applications => dispatch(actGetAllApplicationsSuccess(applications)))
            .catch(error => dispatch(actGetAllApplicationsFailed(error)));
    }
}

const actGetAllApplicationsSuccess = applications => {
  return {
      type: GET_ALL_APPLICATIONS_SUCCESS,
      applications
  }  
}

const actGetAllApplicationsFailed = error => {
    return {
        type: GET_ALL_APPLICATIONS_FAILED,
        error
    };
}

export const actGetApplication = appid => {
    return dispatch => {
        dispatch(actGetApplicationInit())
        return adminApi.getApplication(appid)
            .then(application => dispatch(actGetApplicationSuccess(application)))
            .catch(error => dispatch(actGetApplicationFailed(error)));
    };
}

const actGetApplicationInit = () => {
    return {
        type: GET_APPLICATION
    }; 
}

const actGetApplicationSuccess = application => {
    return {
        type: GET_APPLICATION_SUCCESS,
        application
    }; 
}
  
const actGetApplicationFailed = error => {
    return {
        type: GET_APPLICATION_FAILED,
        error
    };
}

export const actSaveApplication = application => {
    return dispatch => {
        return adminApi.saveApplication(application)
            .then(application => dispatch(actSaveApplicationSuccess(application)))
            .catch(error => dispatch(actSaveApplicationFailed(error)));
    }
}

const actSaveApplicationSuccess = application => {
    return {
        type: SAVE_APPLICATION_SUCCESS,
        application
    }; 
}
  
const actSaveApplicationFailed = error => {
    return {
        type: SAVE_APPLICATION_FAILED,
        error
    };
}

export const actClearApplication = () => {
    return {
        type: CLEAR_APPLICATION_SUCCESS
    }
}

/** Logo Upload */

export const actUploadAppLogo = (id,application) => {
    return dispatch => {
        return adminApi.uploadAppLogo(id,application)
            .then(response => dispatch(actUploadAppLogoSuccess(response)))
            .catch(error => dispatch(actUploadAppLogoFailed(error)));
    }
}

const actUploadAppLogoSuccess = response => {
    return {
        type: UPLOAD_APP_LOGO_SUCCESS,
        response
    }; 
}
  
const actUploadAppLogoFailed = error => {
    return {
        type: UPLOAD_APP_LOGO_FAILED,
        error
    };
}