import React, { Component } from 'react';
import OktaRedirect from '../ssologin/redirect';
import {IDP_URL} from '../../api/constants';

class IdpbRedirect extends Component {
    state = {
        error: {},
        redirectUrl: null,
    }

    componentDidMount = () => {
        const { match } = this.props; 
        const { tenant } = match.params;
        let urlString = this.props.location.search;
        urlString = urlString.replace('AppUrl%3D', 'AppUrl=');
        let params = new URLSearchParams(urlString); 
        const fromURI = params.get('AppUrl');
        this.updateRedirectUrl(fromURI, tenant);
    }

    componentDidUpdate = (prevProps) => {
       
    }

    render() {
        const { authError } = this.props;
        const { redirectUrl } = this.state;
        return ( 
            <div className="app-container"> 
                {  
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                } 
            </div>
        );
    }

    updateRedirectUrl = (from_uri, tenant) => {
        let redirectUrl = `${IDP_URL}?fromURI=${window.location.origin}/sso/session/ext?AppUrl=${from_uri}`;
        if (tenant == 'ext'){
            redirectUrl = `${from_uri}`;
            
            if (sessionStorage.getItem('urlparam')) {
                redirectUrl = sessionStorage.getItem('urlparam');
            }
        }
        this.setState({redirectUrl});
    }
}

export default (IdpbRedirect);