import { SET_ID_TOKEN_TO_STORAGE, SET_ID_TOKEN_TO_STORAGE_SUCCESS, SET_ID_TOKEN_TO_STORAGE_FAILED, GET_ID_TOKEN_FROM_STORAGE, GET_ID_TOKEN_FROM_STORAGE_SUCCESS, GET_ID_TOKEN_FROM_STORAGE_FAILED, CLEAR_ID_TOKEN_FROM_STORAGE, CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS, CLEAR_ID_TOKEN_FROM_STORAGE_FAILED } from "../actionTypes";

export const actSetIdTokenToLocalStorage = idToken => {
    return dispatch => {
        actSetIdTokenToLocalStorageInit();
        try {
            localStorage.setItem('idToken', idToken);
            dispatch(actSetIdTokenToLocalStorageSuccess(idToken));
        } catch(e) {
            dispatch(actSetIdTokenToLocalStorageFailed(e));
        }
    }
}

const actSetIdTokenToLocalStorageInit = () => {
    return {
        type: SET_ID_TOKEN_TO_STORAGE
    };
}

const actSetIdTokenToLocalStorageSuccess = (idToken) => {
    return {
        type: SET_ID_TOKEN_TO_STORAGE_SUCCESS,
        idToken
    };
}

const actSetIdTokenToLocalStorageFailed = (error) => {
    return {
        type: SET_ID_TOKEN_TO_STORAGE_FAILED,
        error
    };
}


export const actGetIdTokenFromLocalStorage = () => {
    return dispatch => {
        actGetIdTokenFromLocalStorageInit();
        try {
            const idToken = localStorage.getItem('idToken');
            dispatch(actGetIdTokenFromLocalStorageSuccess(idToken));
        } catch(e) {
            dispatch(actGetIdTokenFromLocalStorageFailed(e));
        }
    }
}

const actGetIdTokenFromLocalStorageInit = () => {
    return {
        type: GET_ID_TOKEN_FROM_STORAGE
    };
}

const actGetIdTokenFromLocalStorageSuccess = (idToken) => {
    return {
        type: GET_ID_TOKEN_FROM_STORAGE_SUCCESS,
        idToken
    };
}

const actGetIdTokenFromLocalStorageFailed = (error) => {
    return {
        type: GET_ID_TOKEN_FROM_STORAGE_FAILED,
        error
    };
}


export const actClearIdTokenFromLocalStorage = () => {
    return dispatch => {
        actClearIdTokenFromLocalStorageInit();
        try {
            localStorage.removeItem('idToken');
            dispatch(actClearIdTokenFromLocalStorageSuccess());
        } catch(e) {
            dispatch(actClearIdTokenFromLocalStorageFailed(e));
        }
    }
}

const actClearIdTokenFromLocalStorageInit = () => {
    return {
        type: CLEAR_ID_TOKEN_FROM_STORAGE
    };
}

const actClearIdTokenFromLocalStorageSuccess = () => {
    return {
        type: CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS
    };
}

const actClearIdTokenFromLocalStorageFailed = (error) => {
    return {
        type: CLEAR_ID_TOKEN_FROM_STORAGE_FAILED,
        error
    };
}