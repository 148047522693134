import { connect } from "react-redux";
import ProfileActivate from './ProfileActivate';
import { actActivateProfile } from '../../store/actions/profileActions';

const mapStateToProps = state => {
    return {
        activateRedirect: state.profile.activateRedirect,
        application: state.admin.application,
        error: state.profile.error,
        status: state.profile.status,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actAccount: (url, payload) => dispatch(actActivateProfile(url, payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActivate);