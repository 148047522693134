import React from 'react';
import TextInput from "../../shared/TextInput";
import ButtonInput from "../../shared/ButtonInput";
import { Card, Grid, CardContent, Typography, Container } from '@material-ui/core';
import PropTypes from "prop-types";
import { ENABLE_ERROR_NOTIFIER } from '../../../utils/constants';
import ErrorMesasge from '../../shared/ErrorMessage';
import PasswordInput from '../../shared/PasswordInput';

const LoginFormComponent = ({ 
    onSignIn, data, onChange, handleKeyPress, handleRouteToFrgtPswd, 
    handleRouteToRegistration, error, apiError
}) => {
    const inputClass = "app-content-text-width";
    const errorInputClass = "app-content-text-width-with-error";
    return (     
        <div className="app-container-fp">
            <Container maxWidth="sm" spacing={5} className="app-content-padding">
                <Card style={{padding: '15px 0px'}} className="shadow-bordered-container">
                    <CardContent>
                        <Grid item xs={12}>
                            <Typography component="h5" variant="h5" className="app-content-h3-login app-content-center">
                                Access Your Account
                            </Typography>
                        </Grid>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <Typography className="app-content-h4-login app-content-center" component="h6" variant="h6">
                                    Sign in below using your email and password.
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                        <form>
                            <Grid container spacing={3} >
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    {
                                        !ENABLE_ERROR_NOTIFIER && apiError && (
                                            <ErrorMesasge message={apiError}/>
                                        )
                                    }
                                    <TextInput wrapperClass=""
                                        wrapperId="txtUsrName"
                                        labelClass="app-content-label"
                                        label="Email Address "
                                        error={error.username}
                                        errorClass="emr-field-error-message"
                                        inputClass={error.username ? errorInputClass : inputClass}
                                        type="text"
                                        name="username"
                                        value={data.username}
                                        onChange={onChange}
                                        isRequired={true}
                                        onKeyPress={handleKeyPress}
                                        autofocus={true} />
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    <PasswordInput wrapperClass=""
                                        wrapperId="txtPassword"
                                        labelClass="app-content-label"
                                        label="Password "
                                        error={error.sec}
                                        errorClass="emr-field-error-message"
                                        inputClass={error.sec ? errorInputClass : inputClass}
                                        type="password"
                                        name="sec"
                                        value={data.sec}
                                        onChange={onChange}
                                        onKeyPress={handleKeyPress} />
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    <ButtonInput
                                        buttonBaseClass="app-button-primary"
                                        label="SIGN IN"
                                        onClick={onSignIn} />
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>

                            <Grid container spacing={2} className="app-content-center">
                                <Grid item xs={1} md={2}></Grid>
                                <Grid item xs={10} md={8}>
                                    <button 
                                        className="app-button-href"
                                        onClick={handleRouteToFrgtPswd}>
                                        Forgot your password?
                                    </button>
                                </Grid>
                                <Grid item xs={1} md={2}></Grid>
                            </Grid>

                            <Grid container spacing={0} className="app-content-center">
                                <Grid item xs={1} md={2}></Grid>
                                <Grid item xs={10} md={8} className="app-content-label-lower">
                                    <h4 className="app-content-h3">
                                        {"Don't have an account ?"}
                                    </h4>
                                </Grid>
                                <Grid item xs={1} md={2}></Grid>
                            </Grid>

                            <Grid container spacing={0} className="app-content-center">
                                <Grid item md={2}></Grid>
                                <Grid item xs={12} md={8}>
                                    <button
                                        className="app-button-href" 
                                        onClick={handleRouteToRegistration}>
                                        Create an Account
                                    </button>
                                </Grid>
                                <Grid item md={2}></Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}

LoginFormComponent.propTypes = {
    onSignIn: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    handleKeyPress: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    handleRouteToFrgtPswd: PropTypes.func.isRequired,
    handleRouteToRegistration: PropTypes.func.isRequired,
    apiError: PropTypes.object,
}

export default LoginFormComponent;