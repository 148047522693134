export const SET_LOADING = "SET_LOADING";

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILED = 'AUTHENTICATE_USER_FAILED';

export const CLEAR_AUTHENTICATE_ERROR = 'CLEAR_AUTHENTICATE_ERROR';
export const CLEAR_AUTHENTICATE_ERROR_SUCCESS = 'CLEAR_AUTHENTICATE_ERROR_SUCCESS';

export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS';
export const GET_ALL_APPLICATIONS_SUCCESS = 'GET_ALL_APPLICATIONS_SUCCESS';
export const GET_ALL_APPLICATIONS_FAILED = 'GET_ALL_APPLICATIONS_FAILED';

export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILED = 'GET_APPLICATION_FAILED';

export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS';
export const SAVE_APPLICATION_FAILED = 'SAVE_APPLICATION_FAILED';

export const CLEAR_APPLICATION_SUCCESS = 'CLEAR_APPLICATION_SUCCESS';

export const UPLOAD_APP_LOGO = 'UPLOAD_APP_LOGO';
export const UPLOAD_APP_LOGO_SUCCESS = 'UPLOAD_APP_LOGO_SUCCESS';
export const UPLOAD_APP_LOGO_FAILED = 'UPLOAD_APP_LOGO_FAILED';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_PROFILE_SCHEMA = 'GET_PROFILE_SCHEMA';
export const GET_PROFILE_SCHEMA_SUCCESS = 'GET_PROFILE_SCHEMA_SUCCESS';
export const GET_PROFILE_SCHEMA_FAILED = 'GET_PROFILE_SCHEMA_FAILED';

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAILED = 'CREATE_PROFILE_FAILED';

export const ACTIVATE_PROFILE = 'ACTIVATE_PROFILE';
export const ACTIVATE_PROFILE_SUCCESS = 'ACTIVATE_PROFILE_SUCCESS';
export const ACTIVATE_PROFILE_FAILED = 'ACTIVATE_PROFILE_FAILED';

export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED';

export const GET_FGT_SECWORD_RECOVERY_QUESTION = 'GET_FGT_SECWORD_RECOVERY_QUESTION';
export const GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS = 'GET_FGT_SECWORD_RECOVERY_QUESTION_SUCCESS';
export const GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED = 'GET_FGT_SECWORD_RECOVERY_QUESTION_FAILED';

export const RESET_SECWORD = 'RESET_SECWORD';
export const RESET_SECWORD_SUCCESS = 'RESET_SECWORD_SUCCESS';
export const RESET_SECWORD_FAILED = 'RESET_SECWORD_FAILED';

export const CHANGE_SECWORD = 'CHANGE_SECWORD';
export const CHANGE_SECWORD_SUCCESS = 'CHANGE_SECWORD_SUCCESS';
export const CHANGE_SECWORD_FAILED = 'CHANGE_SECWORD_FAILED';

export const CHANGE_PSWD_SEC_QUES_ANS = 'CHANGE_PSWD_SEC_QUES_ANS';
export const CHANGE_PSWD_SEC_QUES_ANS_SUCCESS = 'CHANGE_PSWD_SEC_QUES_ANS_SUCCESS';
export const CHANGE_PSWD_SEC_QUES_ANS_FAILED = 'CHANGE_PSWD_SEC_QUES_ANS_FAILED';

export const GET_EDIT_PROFILE_RECOVERY_QUESTION = 'GET_EDIT_PROFILE_RECOVERY_QUESTION';
export const GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS = 'GET_EDIT_PROFILE_RECOVERY_QUESTION_SUCCESS';
export const GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED = 'GET_EDIT_PROFILE_RECOVERY_QUESTION_FAILED';

export const VERIFY_EDIT_PROFILE_RECOVERY_ANSWER = 'VERIFY_EDIT_PROFILE_RECOVERY_ANSWER';
export const VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS = 'VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_SUCCESS';
export const VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED = 'VERIFY_EDIT_PROFILE_RECOVERY_ANSWER_FAILED';

export const GET_USER_MFA_QUESTIONS = 'GET_USER_MFA_QUESTIONS';
export const GET_USER_MFA_QUESTIONS_SUCCESS = 'GET_USER_MFA_QUESTIONS_SUCCESS';
export const GET_USER_MFA_QUESTIONS_FAILED = 'GET_USER_MFA_QUESTIONS_FAILED';

export const SEND_SECWORD_RESET_EMAIL = 'SEND_SECWORD_RESET_EMAIL';
export const SEND_SECWORD_RESET_EMAIL_SUCCESS = 'SEND_SECWORD_RESET_EMAIL_SUCCESS';
export const SEND_SECWORD_RESET_EMAIL_FAILED = 'SEND_SECWORD_RESET_EMAIL_FAILED';

export const GET_USER_SESSION = 'GET_USER_SESSION';
export const GET_USER_SESSION_SUCCESS = 'GET_USER_SESSION_SUCCESS';
export const GET_USER_SESSION_FAILED = 'GET_USER_SESSION_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED';

export const SET_ID_TOKEN_TO_STORAGE = 'SET_ID_TOKEN_TO_STORAGE';
export const SET_ID_TOKEN_TO_STORAGE_SUCCESS = 'SET_ID_TOKEN_TO_STORAGE_SUCCESS';
export const SET_ID_TOKEN_TO_STORAGE_FAILED = 'SET_ID_TOKEN_TO_STORAGE_FAILED';

export const GET_ID_TOKEN_FROM_STORAGE = 'GET_ID_TOKEN_FROM_STORAGE';
export const GET_ID_TOKEN_FROM_STORAGE_SUCCESS = 'GET_ID_TOKEN_FROM_STORAGE_SUCCESS';
export const GET_ID_TOKEN_FROM_STORAGE_FAILED = 'GET_ID_TOKEN_FROM_STORAGE_FAILED';

export const CLEAR_ID_TOKEN_FROM_STORAGE = 'CLEAR_ID_TOKEN_FROM_STORAGE';
export const CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS = 'CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS';
export const CLEAR_ID_TOKEN_FROM_STORAGE_FAILED = 'CLEAR_ID_TOKEN_FROM_STORAGE_FAILED';