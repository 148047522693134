import axios from 'axios';

/*
    URL References
    Okta: https://  /api/v1
    Sandbox: https://sandbox.gateway.emerson.com/api/aslgrp/emriam/v1
    Admin: https://autosolcommonssocr-api.azurewebsites.net/api/v1

*/
/*
export const SSO_ADMIN_BASE_URL = "https://autosolcommonssocr-stage-api.azurewebsites.net/api/v1"; //"http://localhost:5000/api/v1";
export const INSTRUMENTATION_KEY = '1f0b0335-4da8-4c3b-925d-59dc807e651f';
export const REACT_SSO_AUTHORIZATION_PATH="/oauth2/default/v1/authorize";
export const IDP_URL="https://accessemr.okta.com/sso/idps/0oael196q8OoMjOBT1t7";
export const INTERNAL_OKTA_REDIRECT_URL = "https://emerson.okta.com/login/sessionCookieRedirect";
export const EXTERNAL_OKTA_BASE_URL = "https://accessemr.okta.com";
export const EXTERNAL_OKTA_REDIRECT_URL = "https://accessemr.okta.com/login/sessionCookieRedirect";
-------------------------------------------------------------------------------------------------------
export const SSO_ADMIN_BASE_URL = process.env.REACT_APP_SSO_ADMIN_BASE_URL;
export const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY;
export const REACT_SSO_AUTHORIZATION_PATH= process.env.REACT_APP_OKTA_AUTHORIZATION_PATH;
export const IDP_URL= process.env.REACT_APP_IDP_URL_PATH;
export const INTERNAL_OKTA_REDIRECT_URL = process.env.REACT_APP_INTERNAL_OKTA_REDIRECT_URL;
export const EXTERNAL_OKTA_BASE_URL = process.env.REACT_APP_EXTERNAL_OKTA_BASE_URL;
export const EXTERNAL_OKTA_REDIRECT_URL = process.env.REACT_APP_EXTERNAL_OKTA_REDIRECT_URL;
*/

export const SSO_ADMIN_BASE_URL = process.env.REACT_APP_SSO_ADMIN_BASE_URL;
export const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY;
export const REACT_SSO_AUTHORIZATION_PATH= process.env.REACT_APP_OKTA_AUTHORIZATION_PATH;
export const IDP_URL= process.env.REACT_APP_IDP_URL_PATH;
export const INTERNAL_OKTA_REDIRECT_URL = process.env.REACT_APP_INTERNAL_OKTA_REDIRECT_URL;
export const EXTERNAL_OKTA_BASE_URL = process.env.REACT_APP_EXTERNAL_OKTA_BASE_URL;
export const EXTERNAL_OKTA_REDIRECT_URL = process.env.REACT_APP_EXTERNAL_OKTA_REDIRECT_URL;


export const SSO_ADMIN_PUB_URL = `${SSO_ADMIN_BASE_URL}/pub`;

export const axiosOktaExternalBase = axios.create({
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true,
    timeout: 30000
});

export const axiosSsoAdminInstance = axios.create({
    baseURL: SSO_ADMIN_BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    timeout: 30000
});

export const axiosOktaInstance = axios.create({
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App' : 'AutoSol-Emr-SSOApp'
    },
    timeout: 30000
});