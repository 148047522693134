import { adminMaintenanceTableColumns, SECURITY_QUESTIONS } from "../utils/constants";

// Define all the store/reducer initial state values.

export default {
    auth: {
        profile: {},
        error: {},
        recovery: {},
        mfaQuestions: [],
        status: 'NIL',
    },
    admin: {
        applications: [],
        application: {},
        error: {},
        status: 'NIL',
        tableColumns: adminMaintenanceTableColumns
    },
    profile: {
        schema: [],
        mode: 'NIL',
        data: [],
        status: 'NIL',
        error: {},
        recovery: {},
        mfaQuestions: SECURITY_QUESTIONS.map(q=> ({value: q.id, label: q.label, id: q.id})),
        recoveryQuestions: SECURITY_QUESTIONS.map(q=> ({value: q.value, label: q.label, id: q.id})),
        activateRedirect: 'login'
    },
    forgotPassword: {
        error: {},
        status: 'NIL',
    },
    storage: {
        idToken: null
    }
}
