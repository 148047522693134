import React from 'react';
import { Grid, Container } from '@material-ui/core';
import PropTypes from "prop-types";
import { ENABLE_ERROR_NOTIFIER } from '../../../utils/constants';

const MessageComponent = ({message}) => {
    let messageType = message.type;
    let icon = '';
    let animationClass = "animated fadeInDown fast";
    let color = '#FFF';
    switch(messageType) {
        case 'error':
            icon = 'x';
            color = '#ff4d4d';
            break;
        case 'warning':
            icon = '!';
            color = 'orange';
            break;
        case 'info':
            icon = 'i';
            color = '#ddd';
            break;
        default:
            animationClass= 'animated bounceOut fast'
            break;
    }
    

    return (
        
            ENABLE_ERROR_NOTIFIER 
            ? (
                <Container maxWidth="sm" 
                    className={"messsage-section " + animationClass}>
                    <Grid container className="message-container" style={{background: color}}>
                        <Grid item xs={2} className="message-icon-container">
                            <div className="message-icon" style={{color}}>
                                <span>{icon}</span>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <p className="message-header">{message.title}</p>
                            <p className="message-description">{message.description}</p>
                        </Grid>
                    </Grid>
                </Container>
            )
            : null
        
        
    );
}

MessageComponent.propTypes = {
    message: PropTypes.object.isRequired
}

export default MessageComponent;