import React from 'react';
import AppRouter from "./routes";

import './App.css';

const App = () => {
  // const appInsights = new ApplicationInsights({ config: {
  //   instrumentationKey: '11742bac-8de1-42be-8645-6dce42232aa1'
  //   /* ...Other Configuration Options... */
  // } });
  //appInsights.loadAppInsights();
  return (
    <AppRouter />
  );
}

export default App;


