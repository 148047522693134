
import { connect } from 'react-redux'; 
import ResetPassword from './ResetPassword';
import { actChangePassword } from '../../../store/actions/profileActions';

const mapStateToProps = (state) => {
    return {
        recovery: state.profile.recovery,
        error: state.profile.error,
        status: state.profile.status,
        profile: state.profile.data,
        application: state.admin.application,
        idToken: state.storage.idToken,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (url, payload) => dispatch(actChangePassword(url, payload))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
