import React, { Component } from 'react';
import PropTypes from "prop-types";

class OktaRedirect extends Component {
    UNSAFE_componentWillMount() {
        window.location = this.props.loc;
    }
    render() {
        return (
            <div></div>
        )
    }
}

OktaRedirect.propTypes = {
    loc: PropTypes.string.isRequired
}

export default OktaRedirect;