import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { Container, Grid, CardContent, Typography, Divider } from '@material-ui/core';
import ButtonInput from './ButtonInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import CountryInput from './CountryInput';
import StateInput from './StateInput';
import NumberInput from './NumberInput';

const DynamicForm = ({
    schema, classes, formButtons, summaryButtons, allowEdit, 
    values, error, options, handleChange, handleKeyPress
}) => {

    // NULL handling for parameters
    formButtons = formButtons ? formButtons : [];
    const schemaCopy = Object.assign([], JSON.parse(JSON.stringify(schema)))
    const formData = schema ? updateFormButtons(schemaCopy, formButtons) : [];

    const [formDataObj, setFormDataObj] = useState(values);

    // For re-rendering the changes
    useEffect(() => {
        setFormDataObj(values);
    }, [values]);

    const onChange = (e) => {
        const field =  e.target.name;
        const value =  e.target.value;        
        setFormDataObj({ ...formDataObj, [field]: value})
        handleChange(e, { ...formDataObj, [field]: value});
    }

    return(
        <CardContent style={{width: '100%'}}>
            <Divider variant="middle" />
            {
                formData && formData.map((parentObject, index) => {
                    return (
                        <CardContent key={index}>
                            <div className={classes.content}>
                                <Typography gutterBottom variant="h6" component="h6" className="dynamic-group-title">
                                    {parentObject.group}
                                </Typography>
                                <Grid container>
                                {
                                    <Container className="dynamic-form-container" style={{padding : "0"}}>
                                        <Grid container >
                                            {
                                                parentObject && parentObject.data.map((item, i) => {
                                                    let md = 6,isNonGrid=true;
                                                    if(item.name === 'password-strength' 
                                                        || item.name === 'forgot-question'
                                                        || item.name==='securuty-strength' ) {
                                                        md = 12
                                                    }

                                                    if(item.required){
                                                        return (
                                                            <Grid key={i} item xs={12} md={md} className="dynamic-ctrl-item">
                                                            <span className="fiels-required-span">*</span>
                                                            {
                                                                generateFormControl(item, onChange, formDataObj, allowEdit, options, error, (i + index), handleKeyPress)
                                                            }
                                                            </Grid>
                                                        );
                                                    }else{
                                                        return (
                                                            <Grid key={i} item xs={12} md={md} className="dynamic-ctrl-item">
                                                            {
                                                                generateFormControl(item, onChange, formDataObj, allowEdit, options, error, (i + index), handleKeyPress)
                                                            }
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Container>
                                }
                                </Grid>
                            </div>  
                            <Divider variant="middle" />
                        </CardContent  >
                    );
                })
            }
            <Grid container className={classes.buttonContent}>
                {
                    summaryButtons && summaryButtons.map(btn => 
                        <Grid key={btn.id} item xs={12} md={6} className="dynamic-ctrl-item">
                            <ButtonInput
                                onClick={(e) => btn.onClick(e, formDataObj)}
                                buttonBaseClass={btn.class}
                                label={btn.label} />
                        </Grid>
                    )
                }
            </Grid>
        </CardContent>
    )
}

const updateFormButtons = (formData, formButtons) => {
    let updatedFormData = formData.map(groupObj => {
        let group = groupObj.group;
        formButtons.map(btn => {
            let btnGroup = btn.group;
            if(group === btnGroup) {
                let btnAfter = btn.after;
                let index = groupObj.data.findIndex(c => c.name === btnAfter) || 0;
                let btnObject = {
                    inputClass: btn.class || '',
                    label: btn.label || '',
                    labelClass: btn.labelClass || '',
                    maxLength: btn.maxLength,
                    minLength: btn.minLength,
                    name: btn.name,
                    type: btn.type,
                    wrapperClass: btn.wrapperClass,
                    onClick: btn.onClick
                }
                groupObj.data.splice((index + 1), 0, btnObject)
            }
            return btn;
        })
        return groupObj
    })
    return updatedFormData; 
}

const generateFormControl = (
    data, onChange, formDataObj, allowEdit, options, error, index, handleKeyPress
) => {
    let type = data.type;
    // if(data.name === "primaryphone" || data.name === 'mobilephone') {
    //     type = 'phone';
    // }else if(data.name === "zipcode"){
    //     type = 'zip';
    // }
    let control = '';
    const autofocus = index === 0;
    
    const errorInputClass = "app-content-text-width-with-error";
    let disabled =  ( data.mutability && data.mutability === "READ" ? true : false);
    let disableClass='';
    // will consider only if view mode
    if(!allowEdit) {
        disabled = true;
        disableClass="disableInupt"
    }
    const inputClass =  data.inputClass + " " + disableClass;
    switch(type) {
        case 'string':
            control = <TextInput type='text'
                label={data.label} name={data.name} 
                //value= {!allowEdit?data.value:formDataObj[data.name]}
                value= {formDataObj[data.name]}
                placeholder={data.placeholder} 
                onChange={onChange}
                wrapperClass={data.wrapperClass}
                labelClass={data.labelClass}
                inputClass= {error[data.name] ?errorInputClass:inputClass}
                error={error[data.name]}
                disabled={disabled}
                autofocus={autofocus}
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                />;
            break;
        case 'zip':
            control = <NumberInput type='text'
                min={data.min}
                max={data.max || 10 }
                label={data.label} name={data.name} 
                //value= {!allowEdit?data.value:formDataObj[data.name]}
                value= {formDataObj[data.name]}
                placeholder={data.placeholder} 
                onChange={onChange}
                wrapperClass={data.wrapperClass}
                labelClass={data.labelClass}
                inputClass= {error[data.name] ?errorInputClass:inputClass}
                error={error[data.name]}
                disabled={disabled}
                autofocus={autofocus}
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                />;
            break;
        case 'phone':
            control = <PhoneInput 
                label={data.label} name={data.name} 
                value= {formDataObj[data.name]}
                placeholder={data.placeholder} 
                onChange={onChange}
                wrapperClass={data.wrapperClass}
                labelClass={data.labelClass}
                inputClass={error[data.name] ?errorInputClass:inputClass}
                error={error[data.name]}
                disabled={disabled}
                autofocus={autofocus}
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                />;
            break;
        case 'countryCode':
                control = <CountryInput 
                    label={data.label} 
                    name={data.name} 
                    value= {formDataObj[data.name]}
                    placeholder={data.placeholder} 
                    onChange={onChange}
                    wrapperClass={data.wrapperClass}
                    labelClass={data.labelClass}
                    inputClass={error[data.name] ?errorInputClass:inputClass}
                    error={error[data.name]}
                    disabled={disabled}
                    autofocus={autofocus}
                    onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                    />;
                break;
        case 'state':
                control = <StateInput 
                    label={data.label} name={data.name} 
                    value= {formDataObj[data.name]}
                    country = {formDataObj['countryCode']}
                    placeholder={data.placeholder} 
                    onChange={onChange}
                    wrapperClass={data.wrapperClass}
                    labelClass={data.labelClass}
                    inputClass={error[data.name] ?errorInputClass:inputClass}
                    error={error[data.name]}
                    disabled={disabled}
                    autofocus={autofocus}
                    onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                    />;
                break;
        // case 'country':
        //     control = <CountryStateInput 
        //         label={data.label} name={data.name} 
        //         value= {formDataObj[data.name]}
        //         placeholder={data.placeholder} 
        //         onChange={onChange}
        //         wrapperClass={data.wrapperClass}
        //         labelClass={data.labelClass}
        //         inputClass={error[data.name] ?errorInputClass:inputClass}
        //         error={error[data.name]}
        //         disabled={disabled}
        //         autofocus={autofocus}
        //         onKeyPress={(e) => handleKeyPress(e, formDataObj)}
        //         />;
        //     break;

        case 'password':
            control = <PasswordInput type='password'
                label={data.label} name={data.name} 
                //value= {!allowEdit ? data.value : formDataObj[data.name]}
                value= {formDataObj[data.name]}
                placeholder={data.placeholder} 
                onChange={onChange}
                wrapperClass={data.wrapperClass}
                labelClass={data.labelClass}
                inputClass={error[data.name] ?errorInputClass:inputClass}
                error={error[data.name]}
                disabled={disabled}
                autofocus={autofocus}
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                />;
            break;
        case 'array': 
            control = <SelectInput 
                label={data.label} name={data.name} 
                value={formDataObj[data.name]}
                onChange={onChange} 
                defaultOption="--Select--"
                options={options[data.name]}
                wrapperClass={data.wrapperClass}
                labelClass={data.labelClass + " " + disableClass}
                error={error[data.name]}
                disabled={disabled}
                inputClass={error[data.name] ?errorInputClass:inputClass}
                autofocus={autofocus} 
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}
                />;
            break;
        case 'password-strength':
            control = <PasswordStrengthIndicator
                classes={{
                    label: "app-content-label-red app-content-right",
                }}
                password={formDataObj['password'] || ''}
                description={`Password must be a minimum of eight characters 
                    and contain at least one number, one lowercase and one uppercase letter. 
                    Must not contain your email or parts of your email separated by the 
                    following characters: , . _ # @.`} />;
            break;
        case 'securuty-strength':
            control = 
            
                <Typography className="app-content-h4" variant="caption">
                Answers must be a minimum of four characters and cannot be same as user's 
                password or  email address, cannot contain any part of the questions.</Typography>;
            break;
        case 'button':
            control = <ButtonInput 
                label={data.label} 
                buttonBaseClass="app-button-primary"
                onClick={data.onClick}
                onKeyPress={(e) => handleKeyPress(e, formDataObj)}/>
            break;
        default: 
            break;
    }

    return control;
}

DynamicForm.propTypes = {
    schema: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    formButtons: PropTypes.arrayOf(PropTypes.object),
    summaryButtons: PropTypes.arrayOf(PropTypes.object),
    allowEdit: PropTypes.bool.isRequired,
    values: PropTypes.object,
    error: PropTypes.object, 
    options: PropTypes.object,
    handleChange: PropTypes.func,
    handleKeyPress: PropTypes.func,
}

export default DynamicForm;


/*
    Don't REMOVE

    Data Reference Section
    ~~~~~~~~~~~~~~~~~~~~~~
    formData expected data format
    [
        {
            name: 'firstName',
            label : 'First Name',
            placeholder : 'First Name',
            type : 'string',
            required : true,
            mutability : '',
            minLength : 5,
            maxLength : 100,
            masterType : '',
            wrapperClass : '',
            labelClass : 'app-content-label',
            inputClass : 'app-content-text-width',
            value: 'My FirstName'
        },
        {
            name: 'lastName',
            label : 'Last Name',
            placeholder : 'Last Name',
            type : 'string',
            required : true,
            mutability : '',
            minLength : 5,
            maxLength : 100,
            masterType : '',
            wrapperClass : '',
            labelClass : 'app-content-label',
            inputClass : 'app-content-text-width',
            value: 'My LastName'
        },
    ]
*/