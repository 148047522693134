import { connect } from 'react-redux';
import Home from './Home';
import { actGetUserSession, actGetSSOUserSession } from '../../store/actions/profileActions';
import { actGetApplication } from '../../store/actions/adminActions';


const mapStateToProps = (state) => {
    return {
        application: state.admin.application,
        session: state.profile.session,
        appError: state.admin.error,
        authError: state.profile.error,
        adminStatus: state.profile.status || '',
        authStatus: state.auth.status || '',
        profileStatus: state.profile.status || '',
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getApplication: payload => dispatch(actGetApplication(payload)),
        getUserSession: (payload) => dispatch(actGetUserSession(payload)),
        getSSOUserSession: (payload) => dispatch(actGetSSOUserSession(payload)),//New SSO

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)