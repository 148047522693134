import Logout from './Logout';
import { connect } from 'react-redux';
import { actLogoutUser } from '../../store/actions/authenticateActions';
import { actClearIdTokenFromLocalStorage } from '../../store/actions/storageActions';

const mapStateToProps = (state) => {
    return {
        status: state.auth.status,
        error: state.auth.error,
        application: state.admin.application,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: payload => dispatch(actLogoutUser(payload)),
        clearIdTokenFromLocalStorage: payload => dispatch(actClearIdTokenFromLocalStorage(payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
