import React from 'react';
import PropTypes from 'prop-types';

const SelectInput = ({
    label, name, value, error, onChange, onBlur,
    wrapperClass, wrapperId, inputClass, labelClass, errorClass,
    defaultOption, options, disabled, autofocus
}) => {
    value = value ? value : '';
    autofocus = autofocus ? 'autofocus' : '';
    errorClass=errorClass?errorClass:"alert alert-danger";
    return (
        <div className={wrapperClass} id={wrapperId} >
            {
                label && (
                    <label className={labelClass} htmlFor={name}>{label}</label>
                )
            }
            <select className={inputClass} name={name} value={value} id={name}
                onChange={onChange} onBlur={onBlur}
                aria-required="true" disabled={disabled} autoFocus={autofocus}>
                {
                    defaultOption && (
                        <option value={-1}>{defaultOption}</option>
                    )
                }
                {
                    options && options.map((item, i) => {
                        return (
                            <option key={i} value={item.value}>{item.label}</option>
                        )
                    })
                }
            </select>

            {
                error && (
                    <div className={errorClass}>{error}</div>
                )

            }
        </div>
    );
}

SelectInput.propTypes = {
    label: PropTypes.string, 
    name: PropTypes.string.isRequired, 
    value: PropTypes.any, 
    error: PropTypes.string, 
    onChange: PropTypes.func, 
    onBlur: PropTypes.func,
    wrapperClass: PropTypes.string, 
    wrapperId: PropTypes.string, 
    inputClass: PropTypes.string, 
    labelClass: PropTypes.string, 
    errorClass: PropTypes.string,
    defaultOption: PropTypes.string, 
    options: PropTypes.arrayOf(PropTypes.object), 
    disabled: PropTypes.bool, 
    autofocus: PropTypes.bool
}

export default SelectInput;