import { SET_ID_TOKEN_TO_STORAGE, SET_ID_TOKEN_TO_STORAGE_SUCCESS, SET_ID_TOKEN_TO_STORAGE_FAILED, GET_ID_TOKEN_FROM_STORAGE, GET_ID_TOKEN_FROM_STORAGE_SUCCESS, GET_ID_TOKEN_FROM_STORAGE_FAILED, CLEAR_ID_TOKEN_FROM_STORAGE, CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS, CLEAR_ID_TOKEN_FROM_STORAGE_FAILED } from "../actionTypes";
import { STATUS_PROGRESS, STATUS_SAVED, STATUS_FAILED, STATUS_SUCCESS, STATUS_CLEARED } from "../../utils/constants";
import initialState from "../initialState";

const storageReducers = (state = initialState.storage, action) => {
    switch(action.type) {
        case SET_ID_TOKEN_TO_STORAGE:
        case GET_ID_TOKEN_FROM_STORAGE:
        case CLEAR_ID_TOKEN_FROM_STORAGE:
            return {
                ...state,
                status: STATUS_PROGRESS
            };
        case SET_ID_TOKEN_TO_STORAGE_SUCCESS:
            return {
                ...state,
                idToken: action.idToken,
                status: STATUS_SAVED
            };
        case SET_ID_TOKEN_TO_STORAGE_FAILED:
        case GET_ID_TOKEN_FROM_STORAGE_FAILED:
        case CLEAR_ID_TOKEN_FROM_STORAGE_FAILED:
            return {
                ...state,
                status: STATUS_FAILED,
                error: action.error
            };

        case GET_ID_TOKEN_FROM_STORAGE_SUCCESS:
            return {
                ...state,
                idToken: action.idToken,
                status: STATUS_SUCCESS
            };

        case CLEAR_ID_TOKEN_FROM_STORAGE_SUCCESS:
            return {
                ...state,
                idToken: null,
                status: STATUS_CLEARED
            };
          
        default:
            return state;
    }
}

export default storageReducers;