import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { amber, green,blue,red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { ENABLE_ERROR_NOTIFIER } from '../../utils/constants';

let openSnackbarFn;
const notifyDuration = 7000;
const styles = () => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "5px",
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

class Notifier extends React.Component {
  state = {
    open: false,
    message: '',
  }; 

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }
  
  render() {
    const { open, variant } = this.state;
    const { classes } = this.props;
    const onClose = this.onClose;
    const Icon = variantIcon[variant]
    const message = (
      <span 
        id="snackbar-message-id" 
        style={{width:"100%"}} 
        dangerouslySetInnerHTML={{ __html: this.state.message }} />
    );

    return (
      ENABLE_ERROR_NOTIFIER ? 
      <Snackbar
        bodyStyle={{ backgroundColor: 'teal', color: 'coral', top: '75px' }}
        className={clsx(classes[variant], '')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={message}
        autoHideDuration={notifyDuration}
        onClose={this.handleSnackbarClose}
        open={open}
        ContentProps={{
          'aria-describedby': 'snackbar-message-id',
        }}>
        <SnackbarContent
          className={clsx(classes[variant], 'className')}
          aria-describedby="client-snackbar"
          message=
          {
            <span id="client-snackbar" style={{    padding: "0 13px"}} className={classes.message}>
              <Icon  className={clsx(classes.icon, classes.iconVariant)}> </Icon>
              {message}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" style={{position:"absolute",top:"-7px",right:"-8px"}} onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
        </Snackbar>
        :
        <div className={clsx(variant+"_non", 'non_notifier')}>{this.state.message}</div>
    );
  }
  

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: '',
    });
  };

  openSnackbar = ({ message, variant}) => {
    this.setState({ open: true, message, variant: variant }, () => {
      if(!ENABLE_ERROR_NOTIFIER) {
        setTimeout(() => {
          this.handleSnackbarClose();
        }, notifyDuration);
      }
    });
  }

  onClose = ()=>{
    this.setState({ open: false });
  }
}

export function openSnackbar({ message, variant }) {
  openSnackbarFn({ message, variant });
}

Notifier.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(Notifier);