import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CloudOff } from '@material-ui/icons';
import OktaRedirect from '../login/redirect';

class StatusPage extends Component {
    state = {
        redirectUrl: null,
        referrer: '',
    }

    componentDidMount = () => {
        let referrer = document.referrer;
        this.setState({referrer});
    }

    render() {
        let { type, title, description } = this.props;

        const { redirectUrl } = this.state;

        switch(type) {
            case "404":
                title = 'Not Found';
                description = 'Page not found';
                break;
            default:
                title = title ? title : 'Unauthorized';
                description = description ? description : "You're unauthorized to view this page";
                break;
        }

        return (
            <div className="app-container">
                {
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                }
                <div className="status-container">
                    <CloudOff className="status-icon"/>
                    <h4>{title}</h4>
                    <p>{description}</p>
                    <button onClick={this.backToOriginPage}>Close</button>
                </div>
            </div>
        );
    }
    
    backToOriginPage = (e) => {
        e.preventDefault();
        
        const { history, application, clearIdTokenFromLocalStorage } = this.props;
        const { referrer } = this.state;

        if(referrer) {
            this.setState({redirectUrl: referrer}, () => {
                clearIdTokenFromLocalStorage();
            });
        } else {
            clearIdTokenFromLocalStorage();
            history.push(`/${application.app_id}/login`);
        }
    }
}

StatusPage.propTypes = {
    type: PropTypes.string, 
    title: PropTypes.string, 
    description: PropTypes.string,
    history: PropTypes.object, 
    application: PropTypes.object, 
    clearIdTokenFromLocalStorage: PropTypes.func.isRequired,
}

export default StatusPage;