import React from 'react';
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, ButtonBase } from '@material-ui/core';
import TextInput from '../../shared/TextInput';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorMessage from '../../shared/ErrorMessage';

const SecQuesPopUp = ({
  hasProfileError, message, popup, onClose, error, handleSubmit, onChange, handleKeyPress 
}) => {
  const inputClass = "app-content-text-width";
  const errorInputClass = "app-content-text-width-with-error";
  return (
    <div >
      <Dialog 
        open={popup.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id = "alert-dialog-title" className ="dialog-title" >
          {popup.title}
          <IconButton aria-label="close" className ="close-icon" onClick = { onClose } disableTouchRipple = { true }>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {popup.description}
          </DialogContentText>

          <DialogContentText id="alert-dialog-description">
           {popup.question}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-no-scroll-bar">
        
          <Grid container spacing={3} style={{ paddingBottom: "10px"}} >
          <Grid container spacing={3} className="">
              <Grid item xs={1} ></Grid>
              <Grid item xs={10}>
                  { hasProfileError && <ErrorMessage message={message}/> }
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <TextInput wrapperClass=""
                wrapperId="answer"
                labelClass="app-content-label"
                label="SECURITY ANSWER "
                errorClass="emr-field-error-message"
                inputClass={error.answer ? errorInputClass : inputClass}
                type="password"
                name="answer"
                value={popup.answer}
                onChange={onChange}
                isRequired={true}
                onKeyPress={handleKeyPress} 
              />
            </Grid>
            <Grid item xs={5} md={5}>
                <ButtonBase
                  className="app-button-primary"
                  style = {{marginTop:"27px"}}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  SUBMIT
                  </ButtonBase>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SecQuesPopUp.propTypes = {
  hasProfileError: PropTypes.bool.isRequired, 
  message: PropTypes.object.isRequired, 
  popup: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired, 
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired, 
  handleKeyPress: PropTypes.func.isRequired
}

export default SecQuesPopUp;