import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Modal } from '@material-ui/core';

const PageProgress = ({ show }) => {

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    return(
        <div>
            {
                isIE && show && (
                    <LinearProgress/>
                )
            }
            {
                !isIE && (
                    <Modal open={show}>
                        <LinearProgress/>
                    </Modal>
                )
            }
        </div>
    );
}

PageProgress.propTypes = {
    show: PropTypes.bool.isRequired
}

export default PageProgress;