import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import PropTypes from "prop-types";
import DynamicForm from '../shared/DynamicForm';
import { STATUS_SUCCESS, STATUS_VERIFIED, STATUS_FAILED, STATUS_SAVED, INTERNAL_USER_RESTRICTION_MESSAGE, ENABLE_ERROR_NOTIFIER, KEY_ENTER, KEY_TAB } from '../../utils/constants';
import ProfileHeader from './profile-header';
import { prepareFormValuesObject, getRequiredFieldNames, checkError, getOktaUrls, checkIsInternalUser, parseJwt, getSchemaLabels } from '../../utils/helper';
import SecurityQuestionPopUp from './secQuesVerificationPopUp/SecQuesPopUp';
import notify from '../../utils/notify';
import OktaRedirect from '../login/redirect';
import { SSO_ADMIN_BASE_URL, SSO_ADMIN_PUB_URL } from '../../api/constants';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        flexGrow: 1,
        overflow: 'hidden',
        fontFamily: "Helvetica, Arial, sans-serif !important", 
    },
    valueclass:{
        fontSize:"12px"
    },
    card: {
        width: "70%",
        margin: "0 auto"
    },
    bigAvatar: {
        margin: 0,
        width: 80,
        height: 80,
    },
    label: {
        fontWeight: "bold",
        fontSize: '12px !important'
    },
    title: {
        fontSize: "18px",
        fontFamily: "Helvetica, Arial, sans-serif !important",
        fontWeight: '600'
    },
    content: {
        padding: "15px"
    },
    buttonContent: {
        padding: "15px",
    },
    buttonicon: {
        display: "none",
        width: "50%"
    },
    divider: {
        margin: theme.spacing(2, 0),
    },

    container: {
        
    }
});

class ProfileManagement extends Component {
    state = {
        schema: [],
        editMode: false,
        popup: {
            open: false,
            title: '',
            description: '',
            question:'',
            answer:''
        },
        message:'',
        hasProfileError:false,
        error: {},
        referrer: null,
        redirectUrl: null,
        isInternalUser: false,
        login: ''
    }

    componentDidMount = () => {
        const { history, location, match, setIdTokenToLocalStorage, getIdTokenFromLocalStorage } = this.props; 
        const { appId, idtoken } = match.params;
        const editMode = location.pathname === '/edit-profile';
        let referrer = document.referrer;

        if(idtoken) {
            setIdTokenToLocalStorage(idtoken);
            let parsedIdToken = parseJwt(idtoken);
            const email = parsedIdToken.email || '';
            this.setState({
                editMode,
                referrer
            }, () => {
                history.push({
                    pathname: `/${appId}/my-profile`,
                    state: {
                        login: email
                    }
                })
            })
            
        } else {
            getIdTokenFromLocalStorage();
            this.setState({
                editMode,
                referrer
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        const { status, schema, recovery, history, 
            error, application, match, getProfile, location, profile, storageStatus } = this.props;
        const { appId, login} = match.params;

        if(prevProps.location.pathname !== location.pathname) {
            const editMode = location.pathname.includes('/edit-profile');
            
            if(location.pathname.endsWith('/my-profile')) {
                const {login} = location.state

                if(application && application.profile_schema) {
                    let oktaUrls = getOktaUrls(login, application);
                    //let url = oktaUrls.sandboxUrl;
                    let url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
                    const id = login;
                    const includeCredentialSchema = true;
                    const appSpecificSchema = application.profile_schema;
                    const isRegister = false;

                    this.setState({
                        login
                    })
                    // Internal user verification
                    let isInternalUser = checkIsInternalUser(login);
                    if(isInternalUser) {
                        this.setState({ isInternalUser }, () => {
                            if(ENABLE_ERROR_NOTIFIER){
                                this.setState({hasProfileError:false,message:''}) ;
                                notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
                                }else {
                                    this.setState({hasProfileError:true,message:INTERNAL_USER_RESTRICTION_MESSAGE}) 
                                }
                            history.push(`/${appId}/status/unauthorized`);
                        })
                    } else {
                        const { idToken } = this.props;
                        // Get schema & profile
                        getProfile(url, {appSpecificSchema, id, includeCredentialSchema, 
                            editMode, isRegister, idToken});
                    }
                }
            }
        
            this.setState({
                editMode
            });
        }
        if(prevProps.application !== application) {
            let oktaUrls = getOktaUrls(login, application);
            //let url = oktaUrls.sandboxUrl;
            const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
            const id = login;
            const includeCredentialSchema = true;
            const appSpecificSchema = application.profile_schema;
            const {editMode} = this.state
            const isRegister = false;

            // Internal user verification
            let isInternalUser = checkIsInternalUser(login);
            if(isInternalUser) {
                this.setState({ isInternalUser }, () => {
                    if(ENABLE_ERROR_NOTIFIER){
                        this.setState({hasProfileError:false,message:''}) ;
                        notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
                    }else {
                          this.setState({hasProfileError:true,message:INTERNAL_USER_RESTRICTION_MESSAGE}) 
                    }
                    history.push(`/${appId}/status/unauthorized`);
                })
            } else {
                const { idToken } = this.props;
                // Get schema & profile
                getProfile(url, {appSpecificSchema, id, includeCredentialSchema, editMode, isRegister, idToken});
            }
            
        }
        
        
        if(prevProps.status !== status) {            
            if(status === STATUS_SAVED) {
                notify({message: 'Profile has been saved successfully'}, 'success');
                history.push({
                    pathname: `/${appId}/my-profile`,
                    state: { login: profile.login }
                });
            }

            if(status === STATUS_VERIFIED) {

                this.handlePopUpClose();
                history.push({
                    pathname:`/${appId}/edit-profile`,
                    state: { editMode: true }
                });
            }

            if(status === STATUS_FAILED) {
                if(ENABLE_ERROR_NOTIFIER){
                    this.setState({hasProfileError:false,message:''}) ;
                    notify({message: error.description}, 'error');
                }else {
                      this.setState({hasProfileError:true,message:error}) 
                }
               
            }
        }
        
        if(prevProps.schema !== schema) {
            this.setState({
                schema
            })
        }

        if(prevProps.recovery !== recovery) {
            if(status === STATUS_SUCCESS && recovery ) {
                let description = "To continue editing your profile information, please answer your Security Question:";
                let title = "SECURITY QUESTION VERIFICATION";
                this.handlePopUpOpen(title, description, recovery.QuestionText);
            }
        }

        if(prevProps.storageStatus !== storageStatus) {
            if(storageStatus === STATUS_SUCCESS) {
                const { idToken } = this.props;
                let parsedIdToken = parseJwt(idToken);
                const email = parsedIdToken.email || '';
                if(email) {
                    history.push({
                        pathname: `/${appId}/my-profile`,
                        state: {
                            login: email
                        }
                    })
                }
                else {
                    history.push(`/${appId}/status/error`);
                }
            }
        }

        const { idToken } = this.props;
        if(prevProps.idToken !== idToken) {
            const editMode = location.pathname.includes('/edit-profile');
            
            if(location.pathname.endsWith('/my-profile') && idToken) {
                let {login} = location.state || {};
                if(!login) {
                    let parsedIdToken = parseJwt(idToken);
                    login = parsedIdToken.email || '';
                }

                if(application && application.profile_schema && login) {
                    let oktaUrls = getOktaUrls(login, application);
                    //let url = oktaUrls.sandboxUrl;
                    const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}/`;
                    const id = login;
                    const includeCredentialSchema = true;
                    const appSpecificSchema = application.profile_schema;
                    const isRegister = false;

                    this.setState({
                        login
                    })
                    // Internal user verification
                    let isInternalUser = checkIsInternalUser(login);
                    if(isInternalUser) {
                        this.setState({ isInternalUser }, () => {
                            if(ENABLE_ERROR_NOTIFIER){
                                this.setState({hasProfileError: false, message: ''}) ;
                                notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
                            } else {
                                    this.setState({hasProfileError:true,message:INTERNAL_USER_RESTRICTION_MESSAGE}) 
                            }
                            history.push(`/${appId}/status/unauthorized`);
                        })
                    } else {
                        const { idToken } = this.props;
                        // Get schema & profile
                        getProfile(url, {appSpecificSchema, id, includeCredentialSchema, 
                            editMode, isRegister, idToken});
                    }
                }
            }
        
            this.setState({
                editMode
            });
        }

        if(prevProps.profile !== profile) {
            this.setState({
                profileData: profile
            })
        }
    }

    render() {
    
        const { classes, schema, profile, recoveryQuestions } = this.props;
        const { editMode, popup, error, redirectUrl, hasProfileError, 
            message, profileData } = this.state;

        const latestProfileData = profileData || profile;
        
        let summaryButtons = this.prepareSummaryButtons(editMode);
        let formButtons = this.prepareFormButtons(editMode);
        const values = prepareFormValuesObject(Object.assign([], JSON.parse(JSON.stringify(schema))), latestProfileData);
        
        const options = {
            'recoveryquestion': recoveryQuestions
        };

        return (
            <Grid container className="content-area" >
                {  
                    redirectUrl && (
                        <OktaRedirect loc = {redirectUrl}/> 
                    )
                }
                
                <Grid item md={2}></Grid> 
                <Grid item xs={12} md={8} 
                    className="shadow-bordered-container"
                    style={{
                        background: '#FFF',
                    }}>
                    <ProfileHeader 
                        basicData={profile} />
                    { 
                        Object.keys(values).length > 0 && (
                            <DynamicForm 
                                schema={schema} 
                                values={values}
                                classes={classes}
                                formButtons={formButtons}
                                summaryButtons={summaryButtons}
                                options={options}
                                allowEdit={editMode} 
                                handleChange={this.handleFormChange}
                                handleKeyPress={this.handleFormKyePress}
                                error={error}/>
                        )
                    }

                    {
                        popup.open && (
                            <SecurityQuestionPopUp
                                hasProfileError = { hasProfileError } 
                                message = { message }
                                error = { error } 
                                popup = { popup }
                                onClose = { this.handlePopUpClose } 
                                onBlur = { this.handleBlur }
                                onChange = { this.handleChange}
                                handleKeyPress = { this.handleKeyPress }
                                handleSubmit = { this.handleSubmit } 
                            />
                        )
                    }

                </Grid> 
                <Grid item md={2}></Grid> 
                
            </Grid>
        );
    }

    prepareSummaryButtons = (editMode) => {
        let summaryButtons = [];
        if(editMode) {
            summaryButtons = [
                {
                    id: 1,
                    label: 'SAVE CHANGES',
                    onClick: this.saveUser,
                    class: 'app-button-primary'
                },
                {
                    id: 2,
                    label: 'CANCEL',
                    onClick: this.backToProfileViewPage,
                    class: 'app-button-cancel'
                }
            ];
        } else {
            summaryButtons = [
                {
                    id: 3,
                    label: 'EDIT PROFILE INFORMATION',
                    onClick: this.moveToProfileEditPage,
                    class: 'app-button-cancel'
                },
                {
                    id: 2,
                    label: 'CLOSE',
                    onClick: this.backToOriginPage,
                    class: 'app-button-cancel'
                }
            ];
        }
        return summaryButtons;
    }

    prepareFormButtons = (editMode) => {
        let formButtons = [];
        if(!editMode) {
            formButtons = [{
                group: 'Account Details',
                after: 'recoveryanswer',
                name: 'change_password',
                label: 'Change Password',
                labelClass: 'app-content-label',
                class: '',
                type: "button",
                wrapperClass: '',
                onClick: this.initResetPassword,
            }]
        }
        return formButtons;
    }
    
    handleFormKyePress = (event, data) => {
        if (event.key === KEY_ENTER) {
          this.saveUser(event, data);
        } else if(event.key === KEY_TAB) {
          const { schema } = this.props;
          const error = {...this.state.error};
          const field = event.target.name;
          let reqFieldNames = getRequiredFieldNames(schema).filter(f => f === field);
          let schemaLabels = getSchemaLabels(schema);
          let newErrors = checkError(reqFieldNames, data, schemaLabels);
          this.setState({error: {...error, ...newErrors}});
        }
    }

    saveUser = (e, data) => {
        e.preventDefault();
        const { saveProfile, schema, application } = this.props;
        let oktaUrls = getOktaUrls(data.login, application);
        //let url = oktaUrls.sandboxUrl;
        let url = `${SSO_ADMIN_PUB_URL}/${application.app_id}`;
        let reqFieldNames = getRequiredFieldNames(schema);
        let schemaLabels = getSchemaLabels(schema);
        let err = checkError(reqFieldNames, data, schemaLabels);
        let message  = 'Required field values are missing!';
        this.setState({error:err}, () => {
            const { error } = this.state;
            if(Object.keys(error).length === 0) {
                const { idToken } = this.props;
                data.idToken = idToken;
                data["OldLogin"] = data.login;
                saveProfile(url, data);
            }
            else {
                if(ENABLE_ERROR_NOTIFIER){
                    this.setState({hasProfileError:false,message:''}) ;
                    notify({message: message}, 'error');
                }else {
                      this.setState({hasProfileError:true,message}) 
                }
                
            }
        });
    }

    backToProfileViewPage = () => {
        const { history, application, profile } = this.props;
        this.setState({ editMode:  false }, () => {
            history.push({
                pathname: `/${application.app_id}/my-profile`,
                state: { 
                    editMode: false, 
                    login: profile.login 
                }
            });
        })
    }

    moveToProfileEditPage = (e) => {
        e.preventDefault();

        const { getProfileRecoveryQuestion, application, profile, idToken } = this.props;
        const { isInternalUser } = this.state;
        const login = profile.login;

        // Internal user verification
        if(isInternalUser) {
            if(ENABLE_ERROR_NOTIFIER){
                this.setState({hasProfileError:false,message:''}) ;
                notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
            }else {
                  this.setState({hasProfileError:true,message:INTERNAL_USER_RESTRICTION_MESSAGE}) 
            }
            
            return;
        }

        let oktaUrls = getOktaUrls(login, application);
        //let url = oktaUrls.sandboxUrl;
        let url = `${SSO_ADMIN_PUB_URL}/${application.app_id}`;

        let data = {
            Login : login,  
            FactorType : "question",
            idToken
        }
        getProfileRecoveryQuestion(url, data);
    }

    backToOriginPage = (e) => {
        e.preventDefault();
        
        const { history, application, clearIdTokenFromLocalStorage } = this.props;
        const { referrer } = this.state;

        if(referrer) {
            this.setState({redirectUrl: referrer}, () => {
                clearIdTokenFromLocalStorage();
            });
        } else {
            clearIdTokenFromLocalStorage();
            history.push(`/${application.app_id}/login`);
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let popup = Object.assign({}, this.state.popup);
        let error = Object.assign({}, this.state.error);
        popup[name] = value;
        this.setState({ popup }, () => {
            if(error[name]) {
                let newError = this.validator(name);
                this.setState({error: newError})
            }
        });
    }

    handleFormChange = (event, profileData) => {
        const field = event.target.name;
        const error = {...this.state.error};
        let newErrors = {};
    
        if(error.hasOwnProperty(field)) {
          const { schema } = this.props;
          delete error[field];
          let reqFieldNames = getRequiredFieldNames(schema).filter(f => f === field);
          let schemaLabels = getSchemaLabels(schema);
          newErrors = checkError(reqFieldNames, profileData, schemaLabels);
        }
    
        this.setState({ profileData, error: {...error, ...newErrors} });
        
    };

    handleBlur = event => {
        this.validator(event.target.name);
    }

    handleKeyPress  = event => {
        if (event.key === KEY_ENTER) {
            this.handleSubmit();
        }
    }

    handleSubmit = () => {
        let err = this.validator('answer');
        const { verifyProfileRecoveryAnswer, recovery, application, idToken } = this.props;
        const { popup } = this.state;
        let oktaUrls = getOktaUrls(recovery.Login, application);
        //let url = oktaUrls.sandboxUrl;
        const url = `${SSO_ADMIN_PUB_URL}/${application.app_id}`;
        let data = {
            Login: recovery.Login, 
            FactorType: recovery.FactorType, 
            FactorId: recovery.FactorId, 
            QuestionAnswer: popup.answer,
            idToken
        }
        if(Object.keys(err).length === 0) {
           
           verifyProfileRecoveryAnswer(url, data);
        }
        
    }
    
    validator = (name) => {
        let error = Object.assign({}, this.state.error);
        let popup = Object.assign({}, this.state.popup);
        let message = 'Required field values are missing!';
        if(validator.isEmpty(popup[name] || '')) {
            error[name] = "Security Answer can not be empty."
        }
        else{
            delete error[name] ;
        }
        this.setState({error});
        if(Object.keys(error).length > 0) {
            if(ENABLE_ERROR_NOTIFIER){
                this.setState({hasProfileError:false,message:''}) ;
                notify({message: message}, 'error');
            }else {
                  this.setState({hasProfileError:true,message}) 
            }
         
        }
        return error;
    }

    handlePopUpOpen = (title, description,question) => {
        let popup = {
            ...this.state.popup,
            open: true,
            title,
            description,
            question,
            answer:''
        };
        this.setState({popup,error:{}});
    }

    handlePopUpClose = () => {
        let popup = {
            ...this.state.popup,
            open: false,
            question:'', 
        };
        this.setState({popup});
        
    }

    initResetPassword = (e) => {
        e.preventDefault();

        const { history, match } = this.props;
        const { appId } = match.params;
        const { isInternalUser, login } = this.state;

        // Internal user verification
        if(isInternalUser) {
            if(ENABLE_ERROR_NOTIFIER){
                this.setState({hasProfileError:false,message:''}) ;
                notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error');
            }else {
                  this.setState({hasProfileError:true,message:INTERNAL_USER_RESTRICTION_MESSAGE}) 
            }
            return;
        }

        history.push({
            pathname:`/${appId}/profile-password-reset`,
            state: { id: login}});
    }
}

ProfileManagement.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object, 
    match: PropTypes.object, 
    setIdTokenToLocalStorage: PropTypes.func.isRequired, 
    getIdTokenFromLocalStorage: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    saveProfile: PropTypes.func.isRequired,
    getProfileRecoveryQuestion: PropTypes.func.isRequired,
    clearIdTokenFromLocalStorage: PropTypes.func.isRequired,
    verifyProfileRecoveryAnswer: PropTypes.func.isRequired,
    status: PropTypes.string,
    schema: PropTypes.object,
    recovery: PropTypes.object,
    error: PropTypes.object, 
    application: PropTypes.object,
    profile: PropTypes.object, 
    storageStatus: PropTypes.string,
    idToken: PropTypes.string,
    classes: PropTypes.object,
    recoveryQuestions: PropTypes.arrayOf(PropTypes.object),
    
}

export default withStyles(styles)(ProfileManagement);