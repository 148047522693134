import React from 'react';
import PropTypes from "prop-types";
import { createTheme, withStyles } from '@material-ui/core/styles'
import { ButtonBase, MuiThemeProvider, Card, Grid, CardContent, Typography, Icon } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import validator from 'validator';
import SubmitIcon from "@material-ui/icons/KeyboardArrowRight";
import TextInput from "../../shared/TextInput";
import { STATUS_FAILED,STATUS_SUCCESS, INTERNAL_USER_RESTRICTION_MESSAGE, ENABLE_ERROR_NOTIFIER, KEY_ENTER, KEY_TAB } from '../../../utils/constants';
import notify from '../../../utils/notify';
import { getOktaUrls, checkIsInternalUser, packEmail } from '../../../utils/helper';
import ErrorMesasge from '../../shared/ErrorMessage';

const theme = createTheme();

class ForgotPassword extends React.Component {

    state = {
        error: {},
        email: "",
        helperTextForEmail: "",
        isEmailSent: false,
        stateToken:"",
        hasProfileError:false
    }
    
    componentDidUpdate = (prevProps) => {
        const { status, error, recovery } = this.props;

        if(prevProps.recovery !== recovery) {
            // if(credentials.StateToken !== "") {
                this.setState({stateToken:recovery.StateToken},()=>{
                    this.sentEmail();
                })
            //}
        }

        if(prevProps.status !== status) {
            if(status === STATUS_SUCCESS) {
               this.setState({isEmailSent:true})
            }
            if(status === STATUS_FAILED) {
                if(ENABLE_ERROR_NOTIFIER){
                    notify({message: error.description}, 'error');
                }else {
                    this.setState({hasProfileError: true}) 
                }
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { error, isEmailSent, hasProfileError } = this.state;
        let emailInputClass = error.email ? "app-content-text-width-with-error" : "app-content-text-width";

        return (
            <div className="app-container-fp">
                <Container maxWidth="sm" spacing={5} className="app-content-padding">
                    <Card className="shadow-bordered-container">
                        <CardContent >
                            <div className="app-content">
                                <br />
                                <Grid item xs={12}>
                                    <Typography component="h5" variant="h5" className="app-content-h2 app-content-left ie10up">
                                        Forgot Your Password?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="app-content-h4 app-content-left" component="h6" variant="h6">
                                        Enter the username (Email address) associated with your registered account to receive an email with a password reset link.
                                    </Typography>
                                </Grid>

                                <Grid container spacing={3} className="">
                                    <Grid item xs={12} md={12}>
                                        { hasProfileError && <ErrorMesasge message={this.props.error}/> }
                                    </Grid>
                                </Grid>
                                
                                <Grid container spacing={3} className="">
                                    <Grid item xs={12} md={12}>
                                        <TextInput wrapperClass=""
                                            wrapperId="txtUsrName"
                                            labelClass="app-content-label"
                                            label="Email Address "
                                            error={error.email}
                                            errorClass="emr-field-error-message"
                                            inputClass={emailInputClass}
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            isRequired={true}
                                            onKeyPress={this.handleKeyPress}
                                            autofocus={true}
                                        />
                                    </Grid>
                                </Grid>
                                    
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} className="app-content-right">
                                        <MuiThemeProvider theme={theme}>
                                            <ButtonBase
                                                className="app-button-primary"
                                                variant="contained"
                                                color="primary"
                                                onClick={this.sentEmail2}>RESET PASSWORD
                                            <SubmitIcon className={classes.extendedIcon} />
                                            </ButtonBase>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={6} md={6} className="app-content-left">
                                        <MuiThemeProvider theme={theme}>
                                            <ButtonBase
                                                className="app-button-cancel"
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleCancelClick}
                                            >
                                                CANCEL
                                            </ButtonBase>
                                        </MuiThemeProvider>
                                    </Grid>
                                </Grid>
                                {
                                    isEmailSent &&
                                    (
                                        <Grid container spacing={3}>
                                            <Grid item xs={1}>
                                                <Icon  className="email-sent-alert-icon">checked</Icon>
                                            </Grid>
                                            <Grid item xs={10} styel={{   paddingLeft: "5%"}}>
                                                <span className="email-sent-alert-title">Password reset link sent</span>
                                                <div className="email-sent-alert-desc">
                                                    <p>
                                                        Please check the email associated with your account for instructions on resetting your password.
                                                    </p>
                                                </div>  
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                        </Grid> 
                                    )
                                }
                            </div>
                            <br />
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
    }

    handleCancelClick =() => {
        const { application, history } = this.props;
        history.push(`/${application.app_id}/login`);
    }

    handleChange = event => {
        this.setState({ email: event.target.value })
    };
    
    validator = () => {
        let error = {...this.state.error};
        if (!validator.isEmail(this.state.email)) {
            error['email'] = "The email entered is not in a valid format.";
        }
        else {
            delete error['email'];
        }
        return error;
    }

    handleKeyPress = (event) => {
        if (event.key === KEY_ENTER) {
            event.preventDefault();
            this.sentEmail2();
        } else if(event.key === KEY_TAB) {
            let error = this.validator();
            this.setState({error})
        }
    }

    // initResetPassword = () => {
    //     const { email } = this.state;
    //     const { getRecoveryQuestion, application } = this.props;

    //     // Internal user verification
    //     let isInternalUser = checkIsInternalUser(email);
    //     if(isInternalUser) {
    //         notify({message: INTERNAL_USER_RESTRICTION_MESSAGE}, 'error')
    //         return;
    //     }

    //     // Send Email with Reset Link
    //     let error =  this.validator();
    //     let payload = { "Login": email }; 
    //     let oktaUrls = getOktaUrls(email, application);
    //     let url = oktaUrls.sandboxUrl;

    //     this.setState({ error }, () => {
    //         if(Object.keys(error).length === 0) {
    //             getRecoveryQuestion(url, payload);
    //         }
    //     });
    // }

    // sentEmail = () => {
    //     const { application, sendPasswordResetEmail } = this.props;
    //     const { email, stateToken } = this.state;
    //     const baseUrl = window.location.origin;
    //     let _email = packEmail(email);
    //     let data = {
    //         email : _email,
    //         baseUrl : baseUrl,
    //         app_id : application.app_id,
    //         state_token : stateToken
    //     }
    //    sendPasswordResetEmail(data);
    // }

    sentEmail2 = () => {
        const { application, sendPasswordResetEmail } = this.props;
        const { email } = this.state;
        const baseUrl = window.location.origin;
        const data = { email: packEmail(email), app_id : application.app_id, baseUrl };
        this.setState({
            isEmailSent: false,
            hasProfileError: false
        }, () => {
            sendPasswordResetEmail(data);
        });
    }
}

ForgotPassword.propTypes = {
    //getRecoveryQuestion: PropTypes.func.isRequired,
    sendPasswordResetEmail: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired, 
    error: PropTypes.object.isRequired, 
    recovery: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default withStyles()(ForgotPassword);