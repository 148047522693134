import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";

import Header from '../shared/header';
import Footer from '../shared/Footer';
import Login from '../login';
import SSOLogin from '../ssologin';
import Registration from '../registration';
import ForgotPassword from '../password-management/forgot';
import ConfirmPwd from '../password-management/confirmPassword';
import ResetPassword from '../password-management/reset';
import ProfileManagement from '../profile-management';
import Notifier from '../shared/Notifier';
import StatusPage from '../status-page';
import PageProgress from '../shared/PageProgress';
import notify from '../../utils/notify';
import OktaRedirect from '../login/redirect';
import ProfileActivate from '../profile-activate'
import { STATUS_PROGRESS } from '../../utils/constants';
import Logout from '../logout';
import { getOktaUrls, getBaseUrl, checkIsInternalUser, generateRandomNumber, parseJwt } from '../../utils/helper';
import { REACT_SSO_AUTHORIZATION_PATH, EXTERNAL_OKTA_BASE_URL } from '../../api/constants';
import { ENABLE_ERROR_NOTIFIER } from '../../utils/constants';

// App-Insights
import { ai } from '../../utils/TelemetryService';

const history = createBrowserHistory({ basename: '' });
ai.initialize({ history: history });

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
        backgroundColor: 'black',
        color: 'white'
    },
});

class Home extends Component {
    state = {
        validated: false,
        redirectUrl: null,
    }
    componentDidMount = () => {
        const { match, getApplication } = this.props;
        const { appId } = match.params;

        if(appId && appId !== 'login') {
            getApplication(appId);
        }
    }

    componentDidUpdate = (prevProps) => {
        const { application, session, getUserSession, getSSOUserSession, history, authError, appError, location } = this.props;
        
        let internal_okta_url = application.internal_okta_url;
        let external_okta_url = application.external_okta_url;
        
        // Checking session validation required for the current URL
        const shouldValidateSession = this.checkValidateSessionRequired(location.pathname);
        if(prevProps.application !== application) {
            if(application) {
                // New SSO Org-to-Org
                if(location.pathname.includes('/ssologin') && location.search) {                           
                    this.persistAppUrlParamSessionStorage();              
                }

                if(shouldValidateSession) {
                    if( location.pathname.includes('/activate-profile') ){
                        this.setState({ validated: true });
                    }else{
                        if(application.is_org_org ){
                            // New SSO
                            getSSOUserSession({external_okta_url}); 
                        } else {
                            getUserSession({internal_okta_url, external_okta_url});
                        }
                    }

                } else {
                    this.setState({ validated: true });
                }
            } else {
                this.setState({ validated: true });
                history.push('/status/error');   
            }
        }
        if(prevProps.session !== session) {
            if(session.status === 'ACTIVE') {
                if(location.pathname.includes('/login')) {
                    //Authorize if its login URL
                    let redirectUrl = this.prepareAuthorizeUrl(session);
                    
                    this.setState({
                        redirectUrl
                    });
                }
                //New SSO
                if(location.pathname.includes('/ssologin')) {
                    //Authorize if its ssologin URL
                    let redirectUrl_in = this.prepareSSOAuthorizeUrl(session);
                    
                    this.setState({
                        redirectUrl: redirectUrl_in
                    });
                }

                if(location.pathname.includes('/my-profile')) {
                    this.setState({
                        validated: true
                    })
                }
                
            } else {
                this.setState({redirectUrl: null, validated: true});

                if(shouldValidateSession) {
                    // No Session available, move to login page
                    history.push(`/${application.app_id}/login`);
                }
            }
        }

        if(prevProps.appError !== appError) {
            //notify({message: appError.description}, 'error');
            let message = appError.ErrorSummary? appError.ErrorSummary: appError.description? appError.description:  appError.message;
            notify({message: message }, 'error');
        }

        if(prevProps.authError !== authError) {
            if(location.pathname.includes('/my-profile') && authError.code !== 'E0000068') {
                if(application.is_org_org ){ 
                    history.push(`/${application.app_id}/ssologin`); 
                } else {
                    history.push(`/${application.app_id}/login`);
                }
            }
            this.setState({ validated: true });
        }
    }
    render() {
        const { validated, redirectUrl } = this.state;
        const { application, classes, adminStatus, authStatus, profileStatus } = this.props;

        const showLoader = adminStatus === STATUS_PROGRESS
            || authStatus === STATUS_PROGRESS
            || profileStatus === STATUS_PROGRESS
            || !validated;
        return (
            <div className={classes.root}>
            {
                redirectUrl && (
                    <OktaRedirect loc = {redirectUrl}/> 
                )
            }
                
                {
                    !validated && (
                        <Fragment>
                            <h1>Validating...</h1>
                        </Fragment>
                    )
                }
                <Fragment>
                    <Header/>
                    <div className={classes.main}>
                        <PageProgress show={showLoader} />
                        {
                               !ENABLE_ERROR_NOTIFIER && (<Notifier />)

                        }
                        {
                            validated && application.app_id && (
                                <Switch>
                                    <Route exact path="/:appId/login" render={(props) => <Login {...props} />} />
                                    <Route exact path="/:appId/ssologin" render={(props) => <SSOLogin {...props} />} />
                                    <Route exact path="/:appId/activate-profile/:token" render={(props) => <ProfileActivate {...props} />} />
                                    <Route exact path="/:appId/logout/:idtoken" render={(props) => <Logout {...props} />} />
                                    <Route exact path="/:appId/registration/:loginfrom?" render={(props) => <Registration {...props} />} />
                                    <Route exact path="/:appId/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                                    <Route exact path="/:appId/my-profile/:idtoken" render={(props) => <ProfileManagement {...props} />} />
                                    <Route exact path="/:appId/my-profile" render={(props) => <ProfileManagement {...props} />} />
                                    <Route exact path="/:appId/forgot-password-reset/:id/:token" render={(props) => <ConfirmPwd {...props} />} />
                                    <Route exact path="/:appId/profile-password-reset" render={(props) => <ResetPassword {...props} />} />
                                    <Route exact path="/:appId/edit-profile" render={(props) => <ProfileManagement {...props} />} />
                                    <Route exact path="/:appId/status/:type" render={(props) => <StatusPage {...props} />} />
                                    <Route render={() => <StatusPage type="404"/>}/>
                                </Switch>
                            )
                        }
                        {
                            validated && !application.app_id && (
                                <Switch>
                                    <Route exact path="/status/:type" 
                                        render={(props) => <StatusPage {...props} />} />
                                </Switch>
                            )
                        }
                    </div>
                    <Footer wrapperClass={classes.footer}/>
                    {
                        ENABLE_ERROR_NOTIFIER && (<Notifier />)

                    }
                    
                </Fragment>
            </div>
        );
    }

    prepareAuthorizeUrl = session => {
        const sessionToken = session.id;
        const login = session.login;       
        const { location } = this.props;
        let search = location.search;
        let params = new URLSearchParams(search);
        const state = params.get('state');   
        const { application } = this.props;
        let client_id = '';
        let response_type = '';
        let nonce = '';
        
        let app_redirect_url = application.app_redirect_url;

        const isInternalUser = checkIsInternalUser(login);
        if(isInternalUser) {
            client_id = application.internal_client_id;
        } else {
            client_id = application.external_client_id;
        }
        response_type = 'code token id_token';
        nonce = `&nonce=${generateRandomNumber(7)}`;

        let oktaUrls = getOktaUrls(login, application);
        let baseUrl = getBaseUrl(oktaUrls.oktaUrl);
       let authorizationUrl=baseUrl+REACT_SSO_AUTHORIZATION_PATH;

        let redirectUrl = `${authorizationUrl}?`;
            redirectUrl = `${redirectUrl}client_id=${client_id}`;
            redirectUrl = `${redirectUrl}&scope=openid profile email`;
            redirectUrl = `${redirectUrl}&response_type=${response_type}`;
            redirectUrl = `${redirectUrl}&response_mode=form_post`;
            redirectUrl = `${redirectUrl}&sessionToken=${sessionToken}`;
            redirectUrl = `${redirectUrl}&redirect_uri=${app_redirect_url}`;
             if(state){
                 redirectUrl = `${redirectUrl}&state=${state}`;
             }
             else{
                redirectUrl = `${redirectUrl}&state=landing`;
                redirectUrl = `${redirectUrl}/${login}`;
             }
            


            redirectUrl = `${redirectUrl}${nonce}`;
        
        redirectUrl = encodeURI(redirectUrl);
        
        return redirectUrl;
    }

    //New SSO
    prepareSSOAuthorizeUrl = session => {
        const sessionToken = session.id;
        const login = session.login;       
        const { location } = this.props;
        let search = location.search;
        let params = new URLSearchParams(search);
        const state = params.get('state');   
        const { application } = this.props;
        // https://accessemr.oktapreview.com/home/oidc_client/0oarm8jnajWHbJiQe0h7/aln5z7uhkbM6y7bMy0g7
        // let app_redirect_url = application.app_redirect_url;

        // let redirectUrl = `${EXTERNAL_OKTA_BASE_URL}${app_redirect_url}`;
        let redirectUrl = application.app_redirect_url;   
        redirectUrl = encodeURI(redirectUrl);

        if (sessionStorage.getItem('urlparam')) {
            redirectUrl = sessionStorage.getItem('urlparam');
        }
        
        return redirectUrl;
    }

    // New Save url + queryString in session storage
    persistAppUrlParamSessionStorage = () => {
        const { application, location } = this.props;
        sessionStorage.setItem('urlparam', `${application.app_redirect_url}${location.search}`);
    }

    checkIsValidUser = session => {
        const { match } = this.props;
        
        const { idtoken } = match.params;
        const parsedIdToken = parseJwt(idtoken);
        const login = parsedIdToken.email || '';

        return login === session.login;
    }

    checkValidateSessionRequired = (pathname) => {

        const sessionNotRequiredPaths = ['/registration', '/forgot-password', '/status', '/logout'];
        
        const result = sessionNotRequiredPaths.filter(path => pathname.includes(path))

        return result.length === 0;
    }
}

Home.propTypes = {
    match: PropTypes.object, 
    getApplication: PropTypes.func.isRequired,
    getUserSession: PropTypes.func.isRequired, 
    getSSOUserSession: PropTypes.func.isRequired, //New SSO
    application: PropTypes.object, 
    session: PropTypes.object,
    history: PropTypes.object, 
    authError: PropTypes.object, 
    appError: PropTypes.object, 
    location: PropTypes.object,
    classes: PropTypes.object, 
    adminStatus: PropTypes.object,  
    authStatus: PropTypes.object,  
    profileStatus: PropTypes.object
}

export default withStyles(styles, { withTheme: true }) (Home);